import { ConsentStatusEnum, UserStatus } from '@pypestream/api-services';
import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

export type MyAccountFormValues = {
  firstName: string;
  lastName: string;
  email?: string;
  jobTitle?: string;
  profilePhoto?: string;
  status?: UserStatus;
  agreed?: boolean;
  requiredConsentStatus?: ConsentStatusEnum;
  requiredConsentUpdatedAt?: Date;
  optionalConsentStatus?: ConsentStatusEnum;
  optionalConsentUpdatedAt?: Date;
  defaultLanguage?: string;
  defaultTimezone?: string;
};

interface UseMyAccountFormProps {
  formValues: MyAccountFormValues;
  onSubmit: (values: MyAccountFormValues) => void;
}

export const useMyAccountForm = ({
  formValues,
  onSubmit: onFormSubmit,
}: UseMyAccountFormProps) => {
  const form = useForm<MyAccountFormValues>({
    defaultValues: formValues,
  });

  const { handleSubmit, setValue, watch } = form;

  const watchedValues = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    profilePhoto: watch('profilePhoto'),
    email: watch('email'),
    jobTitle: watch('jobTitle'),
    agreed: watch('agreed'),
    optionalConsentStatus: watch('optionalConsentStatus'),
    requiredConsentStatus: watch('requiredConsentStatus'),
  };

  useEffect(() => {
    Object.entries(formValues).forEach(([key, value]) => {
      setValue(key as keyof MyAccountFormValues, value);
    });
  }, [setValue, formValues]);

  const _onSubmit: SubmitHandler<MyAccountFormValues> = (values, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    onFormSubmit(values);
  };

  const onSubmit = handleSubmit(_onSubmit);

  return { form, watchedValues, onSubmit, setValue };
};
