import { Hero, InlineList, Spacer, Text } from '@pypestream/design-system';
import React, { FC, KeyboardEvent, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToolModal } from '..';
import { useManagerCtxSelector } from '../../xstate/app.xstate';
import { FakeProductCTA, ProductCTA } from '../product-cta';
import './page-header.scss';
import { TranslationComponent } from '@pypestream/translations';

export const PageHeader: FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const [selectedTool, setSelectedTool] = useState<string | undefined>();
  const { tools } = useManagerCtxSelector((ctx) => ({
    tools: ctx.tools,
  }));

  return (
    <>
      <Hero variant="minimal">
        <Text size="xsmall" fontWeight="medium">
          <TranslationComponent i18nKey="manager/common:homepage.header.tools">
            Tools
          </TranslationComponent>
        </Text>
        <Spacer />

        <InlineList width="full">
          {tools.map(
            ({ logo, label, productId, url, disabled, conditional, name }) => {
              const clickAction = conditional
                ? {
                    ...(url
                      ? { url }
                      : {
                          onClick: () => setSelectedTool(productId),
                          onKeyDown: (e: KeyboardEvent) => {
                            if (e.key === 'Enter') setSelectedTool(productId);
                          },
                        }),
                  }
                : {
                    ...(url && url.includes('https')
                      ? { url }
                      : {
                          onClick: () => {
                            if (url) navigate(url);
                          },
                          onKeyDown: (e: KeyboardEvent) => {
                            if (url && e.key === 'Enter') navigate(url);
                          },
                        }),
                  };

              return (
                <ProductCTA
                  test-id={`product-cta-${label?.replace(' ', '-').toLowerCase()}`}
                  logo={logo}
                  name={label}
                  key={label}
                  disabled={disabled}
                  productName={name}
                  {...clickAction}
                />
              );
            }
          )}

          {Array.from(Array(4)).map((_, index) => (
            <FakeProductCTA key={index} />
          ))}
        </InlineList>
      </Hero>
      <ToolModal
        open={!!selectedTool}
        tools={tools}
        selectedTool={selectedTool}
        onClose={() => setSelectedTool(undefined)}
      />
    </>
  );
};
