// type ModuleType = typeof import('./*.svg');

// const allLogos: Record<string, unknown> = import.meta.glob('./*.svg', {
//   eager: false,
// });

import agentAssistLogo from './agent-assist.svg?url';
import analyticsLogo from './analytics.svg?url';
import docsLogo from './docs.svg?url';
import managerLogo from './manager.svg?url';
import orgLogo from './org.svg?url';
import studioLogo from './studio.svg?url';
import proStudioLogo from './pro-studio.svg?url';
import projectLogo from './project-created.svg?url';

export const logos = {
  agentAssist: agentAssistLogo,
  analytics: analyticsLogo,
  docs: docsLogo,
  manager: managerLogo,
  org: orgLogo,
  studio: studioLogo,
  proStudio: proStudioLogo,
  project: projectLogo,
};

// export const logos: Record<string, () => Promise<ModuleType>> = {};

// Object.keys(allLogos).forEach((logo) => {
//   const simpleName = logo
//     .substring(logo.lastIndexOf('/') + 1)
//     .replace('.svg', '') as string;

//   const value: () => Promise<ModuleType> = allLogos[
//     logo
//   ] as () => Promise<ModuleType>;

//   logos[simpleName] = value;
// });
