/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
// Add types for window.navigation for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="navigation-api-types" />

import { PropertyValueMap, html } from 'lit';
import { state, property, customElement } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { transformProfilePicture } from '@pypestream/utils';
import { Ref, createRef } from 'lit/directives/ref.js';
import { ConsentStatusEnum, UserStatus } from '@pypestream/api-services';
import { allLocales, i18n, TranslationData } from '@pypestream/translations';

import {
  CheckboxWC,
  AvatarWC,
  EntityTypes,
  BaseElement,
  watch,
} from '../../components';
import { SmartContext, Timezone } from '../xstate/smart.xstate-utils';
import { smartService } from '../xstate/smart.xstate';
import { Translate } from '../../components/base-element/mixins/translation-mixin';

const onboardingTranslations = allLocales.filter(
  (locale) => locale.namespace === 'onboarding'
) as unknown as TranslationData[];

// @todo: re-install @virtualstate/navigation (so we can use the @virtualstate/navigation/polyfill) once CC upgrades build process

interface OnboardingState {
  userId?: string;
  accountId?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  picture?: string;
  email?: string;
  authMethod?: string;
  requiredConsentStatus?: ConsentStatusEnum;
  optionalConsentStatus?: ConsentStatusEnum;
  currentOptionalConsentStatus?: ConsentStatusEnum;
  status?: UserStatus;
  loading: boolean;
  defaultTimezone?: string;
}

@customElement('ps-onboarding-modal')
export class OnboardingModalWC extends Translate(BaseElement) {
  context: SmartContext;

  @property({ type: Array }) translations: TranslationData[] | undefined =
    onboardingTranslations;

  @watch('translations')
  onTranslationsChanged() {
    if (this.translations?.length) {
      // use method from translation mixin to add external translations to i18next
      // @ts-ignore
      this.loadTranslations(this.translations);
    }
  }

  @watch('lang')
  async onLangChanged() {
    // @ts-ignore
    await this.reloadLanguage();
  }

  @property() onSuccess?: (status?: UserStatus) => void;

  @property() open: boolean | 'always-opened' = false;

  @property({ type: Array }) timezones: Timezone[] = [];

  @property({ type: String }) private defaultLanguage: string = 'en-US';

  @state() private onboardingState: OnboardingState = {
    userId: undefined,
    accountId: undefined,
    firstName: undefined,
    lastName: undefined,
    jobTitle: undefined,
    picture: undefined,
    authMethod: undefined,
    email: undefined,
    loading: false,
    status: undefined,
    requiredConsentStatus: undefined,
    optionalConsentStatus: undefined,
    currentOptionalConsentStatus: undefined,
    defaultTimezone: undefined,
  };

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  connectedCallback() {
    this.setupListeners();

    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    // this.setupListeners();
    // } else {
    //   import('@virtualstate/navigation/polyfill').then(() => {
    //     this.setupListeners();
    //   });
    // }

    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      const currentUserInfo = this.context?.userInfo;
      const currentUserSettings = this.context?.userSettings;
      const currentTimezones = this.context?.timezones;

      this.context = smartState.context;

      if (
        currentUserInfo !== smartState.context.userInfo ||
        currentUserSettings !== smartState.context.userSettings ||
        currentTimezones !== smartState.context.timezones
      ) {
        this.init();
      }
    });
  }

  protected updated(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (changedProperties.has('env')) {
      this.init();
    }
  }

  private _mutationObserver: MutationObserver;

  setupListeners() {
    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    //   window.navigation.addEventListener('navigate', this.init);
    // } else {
    // console.error('window.navigation polyfill failed to load');
    // }
    let oldHref = document.location.href;
    this._mutationObserver = new MutationObserver((mutations) => {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href;

        this.init();
      }
    });

    this._mutationObserver.observe(
      document.querySelector('body') as HTMLElement,
      {
        childList: true,
        subtree: true,
      }
    );
  }

  tearDownListeners() {
    // console.log('tearDownListeners');

    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    //   window.navigation.removeEventListener('navigate', this.init);
    // } else {
    //   console.error('window.navigation polyfill failed to load');
    // }
    this._mutationObserver.disconnect();
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.tearDownListeners();
  }

  async init() {
    const { userInfo, userSettings, timezones } = this.context;

    if (userInfo?.settings?.defaultLanguage) {
      this.lang = userInfo.settings.defaultLanguage.split('-')[0];
    }

    if (userInfo && userSettings) {
      const accountId = userInfo?.defaultAccount?.id;
      const userId = userInfo?.id;
      const email = userInfo?.email;
      const picture = userInfo?.picture;

      // as in manager.xstate
      this.timezones = timezones;

      this.onboardingState = {
        accountId,
        userId,
        email,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        jobTitle: userInfo?.settings?.jobTitle,
        picture: picture ? transformProfilePicture(picture) : undefined,
        authMethod: userSettings?.getLoginInfo?.recommendedAuthMethod,
        status: userInfo?.status,
        loading: false,
        requiredConsentStatus: userInfo?.requiredConsentStatus,
        optionalConsentStatus: userInfo?.optionalConsentStatus,
        currentOptionalConsentStatus: userInfo?.optionalConsentStatus,
        defaultTimezone: userInfo?.settings?.defaultTimezone,
      };

      if (this.open === 'always-opened') {
        this.open = true;
        return;
      }

      if (
        this.onboardingState.requiredConsentStatus !==
        ConsentStatusEnum.Accepted
      ) {
        this.open = true;
      }
    }
  }

  private _updateContext() {
    const userInfo = {
      id: this.onboardingState.userId || '',
      accountId: this.onboardingState.accountId || '',
      firstName: this.onboardingState.firstName || '',
      lastName: this.onboardingState.lastName || '',
      email: this.onboardingState.email || '',
      settings: {
        jobTitle: this.onboardingState.jobTitle,
        defaultLanguage: this.defaultLanguage,
        defaultTimezone: this.onboardingState.defaultTimezone,
      },
      status: UserStatus.Active,
      picture: this.onboardingState.picture,
      requiredConsentStatus: this.onboardingState.requiredConsentStatus,
      requiredConsentUpdatedAt: new Date().toISOString(),
      optionalConsentStatus: this.onboardingState.optionalConsentStatus,
      optionalConsentUpdatedAt:
        this.onboardingState.optionalConsentStatus !==
        this.onboardingState.currentOptionalConsentStatus
          ? new Date().toISOString()
          : undefined,
    };

    smartService.send({
      type: 'updateUserInfo',
      userInfo,
    });
  }

  private _onChange(
    e: Event & {
      target: { name?: string; value?: string; files: FileList | null } | null;
    }
  ) {
    if (!e.target?.name) {
      return;
    }

    this.onboardingState = {
      ...this.onboardingState,
      [e.target?.name as string]: e.target?.value,
    };

    this._updateContext();
  }

  private _onSubmit = async (
    event: Event & {
      preventDefault: () => void;
    }
  ) => {
    try {
      event?.preventDefault();

      if (!this.onboardingState.defaultTimezone) {
        return;
      }

      if (!this.onboardingState.userId || !this.onboardingState.accountId) {
        return;
      }

      const userInfo = {
        id: this.onboardingState.userId,
        accountId: this.onboardingState.accountId,
        firstName: this.onboardingState.firstName || '',
        lastName: this.onboardingState.lastName || '',
        settings: {
          jobTitle: this.onboardingState.jobTitle,
          defaultLanguage: this.defaultLanguage,
          defaultTimezone: this.onboardingState.defaultTimezone,
        },
        status: UserStatus.Active,
        picture: this.onboardingState.picture,
        requiredConsentStatus: this.onboardingState.requiredConsentStatus,
        requiredConsentUpdatedAt: new Date().toISOString(),
        optionalConsentStatus:
          this.onboardingState.optionalConsentStatus !==
          this.onboardingState.currentOptionalConsentStatus
            ? this.onboardingState.optionalConsentStatus
            : undefined,
        optionalConsentUpdatedAt:
          this.onboardingState.optionalConsentStatus !==
          this.onboardingState.currentOptionalConsentStatus
            ? new Date().toISOString()
            : undefined,
      };

      await new Promise((resolve) => {
        smartService.send({
          type: 'updateUser',
          userInfo,
          callback: (res) => resolve(res),
        });
      });

      this.onSuccess?.();
      this.open = false;
    } catch (error) {
      console.error(JSON.stringify(error, null, 2));
    }
  };

  private _onRequiredStatusChange = (e: InputEvent) => {
    if (e.target instanceof CheckboxWC) {
      const nextValue =
        e.target.checked === true
          ? ConsentStatusEnum.Accepted
          : ConsentStatusEnum.Unknown;

      this.onboardingState = {
        ...this.onboardingState,
        requiredConsentStatus: nextValue,
      };

      this._updateContext();
    }
  };

  private _onOptionalStatusChange = () => {
    const nextValue =
      this.onboardingState.optionalConsentStatus !== ConsentStatusEnum.Accepted
        ? ConsentStatusEnum.Accepted
        : ConsentStatusEnum.Unknown;

    this.onboardingState = {
      ...this.onboardingState,
      optionalConsentStatus: nextValue,
    };

    this._updateContext();
  };

  // private onCookiePolicyClick = () => {
  //   const nextValue =
  //     this.onboardingState.optionalConsentStatus !== ConsentStatusEnum.Accepted
  //       ? ConsentStatusEnum.Seen
  //       : this.onboardingState.optionalConsentStatus;

  //   this.onboardingState = {
  //     ...this.onboardingState,
  //     optionalConsentStatus: nextValue,
  //   };
  // };

  avatarRef: Ref<AvatarWC> = createRef();

  protected render() {
    const isGoogleProvider = this.onboardingState.authMethod
      ? this.onboardingState.authMethod === 'google-sso' ||
        this.onboardingState.authMethod === 'oidc'
      : false;

    const cta = (() => {
      if (isGoogleProvider) {
        return '';
      }

      return this.onboardingState.picture
        ? i18n.t('onboarding:changePicture') || 'Change profile picture'
        : i18n.t('onboarding:selectFile') || 'Select a file';
    })();

    return html`<ps-modal
      ?open=${Boolean(this.open)}
      hide-close-btn
      stay-on-click-outside
      stay-on-esc
    >
      <form @submit=${this._onSubmit}>
        <ps-text-title>
          ${i18n.t('onboarding:title') || 'Finish up your profile'}
        </ps-text-title>
        <ps-text-body variant="secondary">
          ${i18n.t('onboarding:subTitle') ||
          'Just a couple more questions for you...'}
        </ps-text-body>

        <ps-spacer size="xlarge"></ps-spacer>

        <ps-block-list gutter="large">
          <ps-input
            placeholder=${i18n.t('onboarding:firstName') || 'First Name'}
            required
            name="firstName"
            value=${ifDefined(this.onboardingState.firstName)}
            ?readonly=${isGoogleProvider}
            @input=${this._onChange}
          ></ps-input>

          <ps-input
            placeholder=${i18n.t('onboarding:lastName') || 'Last Name'}
            required
            name="lastName"
            value=${ifDefined(this.onboardingState.lastName)}
            ?readonly=${isGoogleProvider}
            @input=${this._onChange}
          ></ps-input>

          <ps-filepond
            account-id=${ifDefined(this.onboardingState.accountId)}
            entity-id=${ifDefined(this.onboardingState.userId)}
            entity-type=${EntityTypes.USER}
            .accept=${['image/png', 'image/jpeg']}
            ?readonly=${isGoogleProvider}
            label=${i18n.t('onboarding:picture') || 'Profile Picture'}
            preview-cta=${cta}
            preview-label=${isGoogleProvider
              ? i18n.t('onboarding:upload_profile_picture_on_google') ||
                'To replace or remove, visit your Google account'
              : i18n.t('onboarding:upload_a_file_here') || 'Upload a file here'}
            picture-src=${ifDefined(this.onboardingState.picture)}
            name="picture"
            .onChange=${({ url }: { url: string }) =>
              this._onChange({
                target: { name: 'picture', value: url, files: null },
              } as Event & {
                target: {
                  name?: string;
                  value?: string;
                  files: FileList | null;
                } | null;
              })}
          >
          </ps-filepond>

          <ps-input
            placeholder=${i18n.t('onboarding:jobTitle') || 'Job Title'}
            name="jobTitle"
            value=${ifDefined(this.onboardingState.jobTitle)}
            @input=${this._onChange}
          ></ps-input>

          <ps-select
            required
            placeholder="Select Timezone"
            name="defaultTimezone"
            .data=${this.timezones}
            .searchKeys=${['searchKeys', 'identifier', 'label']}
            @input=${this._onChange}
            value=${ifDefined(this.onboardingState.defaultTimezone)}
          >
            ${this.timezones.map(
              (timezone) => html`
                <ps-select-option key="${timezone.id}" value="${timezone.id}"
                  >${timezone.label}</ps-select-option
                >
              `
            )}
          </ps-select>
        </ps-block-list>

        <ps-spacer size="xlarge"></ps-spacer>

        <ps-stack gutter="xsmall" alignItems="center">
          <ps-checkbox
            size="small"
            name="requiredConsentStatus"
            ?checked=${this.onboardingState.requiredConsentStatus ===
            ConsentStatusEnum.Accepted}
            @change=${this._onRequiredStatusChange}
          ></ps-checkbox>
          <ps-text-body size="small">
            ${i18n.t('onboarding:terms_conditions_prefix') || 'Agree to'}
            <a
              href="https://app.termly.io/document/terms-of-service/85363fa8-fff8-4aeb-963e-cf55bbe4ef7f"
              target="_blank"
              rel="noreferrer"
              >${i18n.t('onboarding:terms_conditions') ||
              'Terms and Conditions'}</a
            >
            ${i18n.t('onboarding:and') || 'and'}
            <a
              href="https://app.termly.io/document/privacy-policy/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
              target="_blank"
              rel="noreferrer"
              >${i18n.t('onboarding:privacy_policy') || 'Privacy Policy'}</a
            >
          </ps-text-body>
        </ps-stack>
        <ps-stack gutter="xsmall" alignItems="center">
          <ps-checkbox
            size="small"
            name="optionalConsentStatus"
            ?checked=${this.onboardingState.optionalConsentStatus ===
            ConsentStatusEnum.Accepted}
            @change=${this._onOptionalStatusChange}
          ></ps-checkbox>
          <ps-text-body size="small">
            ${i18n.t('onboarding:cookie_policy_label_prefix') ||
            'Opt in to the'}
            <a
              href="https://app.termly.io/document/cookie-policy/84d2833f-fd93-4e1e-bcf7-4b18f161aa77"
              target="_blank"
              rel="noreferrer"
              >${i18n.t('onboarding:cookie_policy') || 'Cookie Policy'}</a
            >
            ${i18n.t('onboarding:cookie_policy_label_suffix') ||
            'to help us improve your experience'}
          </ps-text-body>
        </ps-stack>

        <ps-spacer size="medium"></ps-spacer>

        <ps-button
          size="large"
          width="full"
          type="submit"
          ?disabled=${this.onboardingState.requiredConsentStatus !==
            ConsentStatusEnum.Accepted || this.onboardingState.loading}
        >
          ${this.onboardingState.loading
            ? i18n.t('onboarding:loading') || 'Loading...'
            : i18n.t('onboarding:button') || 'Save Changes'}
        </ps-button>
      </form>
    </ps-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-onboarding-modal': OnboardingModalWC;
  }
  enum PSElementTagNameMap {
    'ps-onboarding-modal' = 'ps-onboarding-modal',
  }
}
