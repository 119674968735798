import React, { useRef } from 'react';
import {
  Background,
  ButtonGroup,
  ModalProps,
  PageBody,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

import { useManagerCtxSelector } from '../../xstate/app.xstate';
import {
  PageHeader,
  Loader,
  Sidebar,
  CreateProjectFlow,
  CreateProjectButton,
} from '../../components';
import {
  OrganizationRoleNames,
  useAuthRole,
  useLoadingState,
} from '../../hooks';
import { IPage } from '../types';
import { ProjectsTable } from './projects-table';
import homepageEmptyStateBg from '../../assets/homepage-empty-state.svg?url';
import './homepage.scss';

export const Homepage: React.FC<IPage> = () => {
  const loading = useLoadingState();
  const modalRef = useRef<ModalProps>(null);

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const { projects, userProjectIds } = useManagerCtxSelector((ctx) => ({
    projects: ctx.projects,
    userProjectIds: ctx.userProjectIds,
    orgId: ctx.selectedOrgId,
  }));

  /*
    the method for defining the list of projects should be identical to the similar method in the Mega Menu:
    design-system/src/smart-components/mega-menu/mega-menu.wc.ts
  */
  const projectsWithAccessibleTools = projects?.filter((project) =>
    userProjectIds.includes(project.projectId)
  );

  const createProjectButton = (
    <CreateProjectButton
      onClick={() => {
        if (modalRef.current) {
          modalRef.current.open = true;
        }
      }}
    />
  );
  const isEmptyState = projectsWithAccessibleTools?.length === 0 || loading;

  return (
    <>
      <PageHeader />
      <Background />
      <Sidebar />
      <PageBody background="none">
        <CreateProjectFlow modalRef={modalRef} />
        {loading ? (
          <Loader relative />
        ) : (
          <>
            {isEmptyState ? (
              <div
                style={{
                  backgroundImage: `url(${homepageEmptyStateBg})`,
                  backgroundRepeat: 'no-repeat',
                  // backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '250px',
                  flexGrow: 1,
                  marginLeft: '-3rem',
                  marginRight: '-3rem',
                }}
              >
                <Text
                  size="medium"
                  fontWeight="medium"
                  style={
                    {
                      '--ps-theme-text-color': '#BCB2F2',
                    } as React.CSSProperties
                  }
                >
                  <TranslationComponent i18nKey="manager/common:homepage.projects.nothingToShowHere">
                    Nothing to show here...{' '}
                  </TranslationComponent>
                </Text>
                {isAdminRole && (
                  <>
                    <Text size="2xsmall" className="u-margin-bottom-large">
                      <TranslationComponent i18nKey="manager/common:homepage.projects.toGetStartedCreateProject">
                        To get started, create your first project.
                      </TranslationComponent>
                    </Text>

                    {createProjectButton}
                  </>
                )}
              </div>
            ) : (
              <>
                <section>
                  <Stack alignItems="center" justifyContent="space-between">
                    <TextTitle size="small">
                      <TranslationComponent i18nKey="manager/common:homepage.projects.yourProjects">
                        Your Projects
                      </TranslationComponent>
                    </TextTitle>
                    {isAdminRole && (
                      <ButtonGroup>{createProjectButton}</ButtonGroup>
                    )}
                  </Stack>
                </section>
                <Spacer size="2xlarge" />
                <ProjectsTable projects={projectsWithAccessibleTools} />
              </>
            )}
          </>
        )}
      </PageBody>
    </>
  );
};
