/* eslint-disable react-hooks/exhaustive-deps */
import { DomainTypeEnum } from '@pypestream/api-services';
import {
  Button,
  Modal,
  ModalIcon,
  ModalProps,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Environments,
  FormProjectStateType,
  Loader,
  ProjectEnvironmentsType,
} from '../../../../components';
import { OrganizationRoleNames, useAuthRole } from '../../../../hooks';
import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../../../xstate/app.xstate';
import { Project } from '../../../../xstate/manager.xstate';
import { ProjectDetailsTabs } from '../project-details';
import {
  ProjectSettingsEnvironmentsTab,
  ProjectSettingsGeneralTab,
} from './index';

export const sortEnvironmentsByName = (
  arr: {
    domains: {
      type: DomainTypeEnum | undefined;
      url: string | undefined;
    }[];
    description: string | undefined;
    name: string;
  }[]
) => {
  const namesOrder: {
    [key: string]: number;
  } = {
    [Environments.Production]: 3,
    [Environments.Testing]: 2,
    [Environments.Development]: 1,
  };
  return arr.sort((a, b) => namesOrder[a.name] - namesOrder[b.name]);
};

export const RenderProjectDetailsContent: FC<{
  project?: Project;
  tab?: ProjectDetailsTabs;
  environmentId?: string;
  hideDeleteButton?: boolean;
}> = ({ project, tab, environmentId, hideDeleteButton = false }) => {
  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);
  const modalRef = useRef<ModalProps>(null);

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.open = false;
    }
  };

  const openModal = () => {
    if (modalRef.current) {
      modalRef.current.open = true;
    }
  };

  const navigate = useNavigate();

  const { products, projects, accountId, routes, applets } =
    useManagerCtxSelector((ctx) => ({
      products: ctx.tools?.filter(({ productId }) => {
        return ctx.products?.find(({ id }) => id === productId);
      }),

      projects: ctx.projects,
      applets: ctx.applets,
      accountId: ctx.selectedOrgId,
      routes: ctx.routes,
    }));

  const projectId = project?.projectId;
  const sortedProjectEnvironments = sortEnvironmentsByName(
    (project?.projectEnvironmentConfig || []).map(
      ({ domains, appletInstances, environment, description, id }) => ({
        domains:
          domains?.map(({ type, url }) => ({
            type,
            url,
          })) || [],
        description,
        name: environment?.name || '',
        appletInstances: appletInstances,
        id,
      })
    )
  ) as ProjectEnvironmentsType;

  const navigateToProjects = useCallback(
    () => navigate(routes.projects),
    [navigate, routes.projects]
  );

  const onSubmit = ({
    projectEnvironmentConfigs,
    ...restData
  }: FormProjectStateType) => {
    sendManagerEvent({
      type: 'manager.updateProject',
      projectId,
      ...restData,
      environmentConfig: projectEnvironmentConfigs.map((config) => ({
        domains: config.domains || [],
        description: config.description || '',
        name: config.name,
      })),
    });
  };

  useEffect(() => {
    if (
      project?.projectId &&
      projects?.length &&
      !projects?.find((p) => p.projectId === project?.projectId)
    ) {
      navigateToProjects();
    }
  }, [navigateToProjects, project?.projectId, projects]);

  const form = useForm<FormProjectStateType>({
    defaultValues: {
      name: '',
      description: '',
      projectIconId: '',
      projectIcon: '',
      timeZoneId: '',
      productIds: [],
      applets: [],
      projectEnvironmentConfigs: [],
    },
  });

  useEffect(() => {
    if (!project) return;

    const projectDetails = {
      name: project.name,
      description: project.description,
      projectIconId: project.pictureFile?.publicId,
      projectIcon: project.pictureFile?.url,
      timeZoneId: project.localizationSettings?.timeZone?.id,
      productIds: project.projectProductSettings?.map(
        ({ productId }) => productId
      ),
      applets: applets,
      projectEnvironmentConfigs: sortedProjectEnvironments,
    };

    form.reset({ ...projectDetails });
  }, [project, applets]);

  const generalTab = (
    <ProjectSettingsGeneralTab
      form={form}
      projectId={projectId}
      products={products}
      accountId={accountId}
      disabled={!isAdminRole}
    />
  );

  const { reset, handleSubmit } = form;

  const getLayout = (tabName?: ProjectDetailsTabs) => {
    switch (tabName) {
      case ProjectDetailsTabs.general:
        return generalTab;
      case ProjectDetailsTabs.environments:
        return (
          <ProjectSettingsEnvironmentsTab
            form={form}
            accountId={accountId}
            projectId={projectId!}
            projectEnvironments={sortedProjectEnvironments}
            disabled={!isAdminRole}
            environmentId={environmentId}
          />
        );
      default:
        return generalTab;
    }
  };

  return !project ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader relative />
    </div>
  ) : (
    <form>
      {getLayout(tab)}
      <Stack
        justifyContent={hideDeleteButton ? 'end' : 'space-between'}
        gutter="small"
      >
        <Modal
          test-id="delete-project-modal"
          ref={modalRef}
          size="medium"
          onClose={closeModal}
        >
          <ModalIcon
            name="error"
            slot="header"
            style={{ display: 'flex', justifyContent: 'center' }}
          />
          <Stack slot="header" alignItems="center" direction="column">
            <TextTitle size="small">
              <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.areYouSure">
                Are you sure?
              </TranslationComponent>
            </TextTitle>
            <TextBody variant="secondary">
              <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.youWillLose">
                You&lsquo;ll permanently lose:
              </TranslationComponent>
              <ul>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.accessToProjectTools">
                    access to this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.dataInsideProjectTools">
                    all data inside this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.rolesAssignedForProjectTools">
                    all roles assigned for this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
              </ul>
            </TextBody>
          </Stack>
          <Stack slot="footer" justifyContent="end">
            <Button
              test-id="delete-project-cancel"
              variant="ghost"
              size="large"
              type="button"
              onClick={closeModal}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.cancel">
                Cancel
              </TranslationComponent>
            </Button>
            <Button
              test-id="delete-project-confirm"
              size="large"
              variant="warning"
              data-cy="delete-project"
              type="button"
              onClick={() => {
                sendManagerEvent({
                  type: 'manager.deleteProject',
                  projectId,
                });

                closeModal();
                navigate(routes.projects);
              }}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.removeProject">
                Delete Project
              </TranslationComponent>
            </Button>
          </Stack>
        </Modal>
        {isAdminRole && !hideDeleteButton && (
          <Stack>
            <Button
              test-id="project-details-delete-btn"
              variant="warning"
              size="large"
              onClick={openModal}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.delete">
                Delete Project
              </TranslationComponent>
            </Button>
          </Stack>
        )}

        {isAdminRole && (
          <Stack>
            <Button
              test-id="project-details-cancel-btn"
              variant="secondary"
              size="large"
              onClick={() => {
                reset();
                navigateToProjects();
              }}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.cancel">
                Cancel
              </TranslationComponent>
            </Button>
            <Button
              test-id="project-details-save-btn"
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={!Object.keys(form.formState.dirtyFields).length}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.save">
                Save
              </TranslationComponent>
            </Button>
          </Stack>
        )}
      </Stack>
    </form>
  );
};
