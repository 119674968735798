import {
  BlockList,
  Button,
  Grid,
  GridCell,
  PageBody,
  PageSection,
  Snackbar,
  Spacer,
  TextSubtitle,
  TextTitle,
  Tooltip,
} from '@pypestream/design-system';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../../xstate/app.xstate';
import { IPage } from '../../types';
import PermissionsTable from './permissions-table';

export const UserDetails: FC<IPage> = (props) => {
  const params = useParams();
  const { org_id: orgIdQueryParam = '', user_id: userIdQueryParam = '' } =
    params;

  const { allUserDetails } = useManagerCtxSelector((ctx) => ({
    allUserDetails: ctx.userDetails,
  }));

  const userDetails =
    allUserDetails[userIdQueryParam] && allUserDetails[userIdQueryParam];
  const { state: userDetailsState, userDetailUpdates } = (userDetails &&
    userDetails) || { state: null, userDetailUpdates: null };
  const loadingUserDetails = useManagerStateMatches(
    'orgRelated.ready.userDetails.loading'
  );
  const isIdleUserDetailsState = useManagerStateMatches(
    'orgRelated.ready.userDetails.idle'
  );

  const hasErrors = useManagerStateMatches(
    'orgRelated.ready.userDetails.error'
  );

  const savingUpdates = useManagerStateMatches(
    'orgRelated.ready.userDetails.saveUpdates'
  );

  useEffect(() => {
    sendManagerEvent({
      type: 'manager.loadUserDetails',
      orgId: orgIdQueryParam,
      userId: userIdQueryParam,
    });

    return () => {
      sendManagerEvent({
        type: 'manager.userDetails.discardUpdates',
        userId: userIdQueryParam,
      });
    };
  }, [userIdQueryParam, orgIdQueryParam]);

  useEffect(() => {
    // Fetch user details directly as a fallback when the state machine hasn't initialized for first call.
    if (!userDetailsState && !loadingUserDetails && isIdleUserDetailsState) {
      sendManagerEvent({
        type: 'manager.loadUserDetails',
        orgId: orgIdQueryParam,
        userId: userIdQueryParam,
      });
    }
  }, [
    isIdleUserDetailsState,
    loadingUserDetails,
    orgIdQueryParam,
    userDetailsState,
    userIdQueryParam,
  ]);

  if (hasErrors) {
    return (
      <PageBody>
        <TextTitle size="small" i18nKey="manager/common:genericError">
          Something went wrong: Try again
        </TextTitle>
      </PageBody>
    );
  }

  if (!userDetailsState) {
    if (loadingUserDetails) {
      return (
        <PageBody background="none">
          <PageSection>
            <Loader relative />
          </PageSection>
        </PageBody>
      );
    }
    return null;
  }

  return (
    <PageBody background="none" width="large">
      <TextTitle test-id="user-details-subtitle">
        {userDetailsState.firstName} {userDetailsState.lastName}
      </TextTitle>
      <Spacer size="2xlarge" />
      <>
        <PageSection>
          <TextTitle test-id="user-details-subtitle" size="xsmall">
            Details
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-first-name-label"
                  size="small"
                  variant="secondary"
                >
                  {/* <TranslationComponent i18nKey="manager/preferences:general.name"> */}
                  Name
                  {/* </TranslationComponent> */}
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Grid alignItems="center">
                  <GridCell xsmall="12" medium="6">
                    {userDetailsState.firstName}
                  </GridCell>
                  <GridCell xsmall="12" medium="6">
                    {userDetailsState.lastName}
                  </GridCell>
                </Grid>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-email-label"
                  size="small"
                  variant="secondary"
                >
                  {/* <TranslationComponent i18nKey="manager/preferences:general.email_address"> */}
                  Email address
                  {/* </TranslationComponent> */}
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Tooltip content="To edit your email address, contact an Admin">
                  {userDetailsState.userName}
                </Tooltip>
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>
        <PageSection>
          <TextTitle
            test-id="permissions-table"
            size="xsmall"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.title"
          >
            Permissions & Access
          </TextTitle>
          <Spacer size="large" />
          <PermissionsTable userDetails={userDetailsState} />
          <Snackbar id="snackbar" open={userDetailUpdates.hasPendingUpdates}>
            When you&apos;re ready, save your changes
            <Button
              size="medium"
              variant="secondary"
              slot="action"
              onClick={() => {
                sendManagerEvent({
                  type: 'manager.userDetails.discardUpdates',
                  userId: userIdQueryParam,
                });
              }}
              disabled={savingUpdates}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              variant="primary"
              slot="action"
              onClick={() => {
                sendManagerEvent({
                  type: 'manager.userDetails.saveUpdates',
                  userId: userIdQueryParam,
                  orgId: orgIdQueryParam,
                });
              }}
              disabled={savingUpdates}
            >
              {savingUpdates ? 'Updating...' : 'Update'}
            </Button>
          </Snackbar>
        </PageSection>
      </>
    </PageBody>
  );
};
