import {
  Card,
  Spacer,
  TextSubtitle,
  TextBody,
  Image,
} from '@pypestream/design-system';
import { FC } from 'react';

import { ManagerContext } from '../../xstate/manager.xstate';
import { DEFAULT_SQUARE_ICON_BASE64 } from '..';
import { useFormatTimeWithTimezone } from '../../hooks';

export interface ProjectCardProps {
  project: NonNullable<ManagerContext['projects']>[number];
  url: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({ project, url }) => {
  const timeWithTimeZone = useFormatTimeWithTimezone({
    fromDate: project.updatedAt,
  });

  return (
    <Card size="large" shadow="none" border="default" interactive href={url}>
      <Image
        width="40px"
        height="40px"
        src={project?.pictureFile?.url}
        alt={project?.name}
        fallback={DEFAULT_SQUARE_ICON_BASE64}
      />

      <Spacer size="2xsmall" />
      <TextSubtitle size="small">{project.name}</TextSubtitle>
      <Spacer size="2xsmall" />
      <TextBody size="small" variant="secondary">
        {timeWithTimeZone}
      </TextBody>
    </Card>
  );
};
