import { BlockList, Spacer } from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';
import { useFormContext } from 'react-hook-form';

import { OutputProjectFormType } from '../..';
import { TimeZoneSelect } from '../create-project-flow';
import { ProjectFormButtons } from './project-form-buttons';
import { ProjectTitles } from './project-titles';

export type ProjectLocalizationPropsType = {
  createProject: (props: {
    formData?: Partial<OutputProjectFormType>;
    callback: () => void;
  }) => void;
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
};

export const ProjectLocalization = ({
  createProject,
  nextStep,
  prevStep,
}: ProjectLocalizationPropsType) => {
  const [t] = useTranslation();

  const form = useFormContext();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const translatedRequiredError =
    t('manager/projects:createProject.required', {
      defaultValue: 'This is a required field',
    }) || '';

  const onSubmit = () => {
    createProject({
      callback: nextStep,
      formData: form.getValues(),
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ProjectTitles
        step="project-localization"
        title={
          t('manager/projects:createProject.step4.title', {
            defaultValue: 'Localization',
          }) || ''
        }
        subTitle={
          t('manager/projects:createProject.step4.subTitle', {
            defaultValue:
              'These settings impact default localization preferences, timestamps in logs and metrics, and how to comply with local government regulations - including how and where user data is stored and what features are available.',
          }) || ''
        }
      />
      <Spacer size="xlarge"></Spacer>
      <BlockList gutter="xlarge">
        <TimeZoneSelect
          {...register('timeZoneId', { required: translatedRequiredError })}
          hoist={!window.Cypress}
          help-text={
            errors['timeZoneId']?.message ||
            t(
              'manager/projects:projectDetails.generalTab.languageAndTimezone.helpText.timezone',
              {
                defaultValue: 'Applies only to Analytics',
              }
            ) ||
            ''
          }
          hasError={!!errors['timeZoneId']?.message}
          _form={form}
          required
        />
      </BlockList>
      <Spacer size="xlarge"></Spacer>
      <ProjectFormButtons
        step="project-localization"
        nextStep={handleSubmit(onSubmit)}
        prevStep={prevStep}
      />
    </form>
  );
};
