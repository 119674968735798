import {
  AppletInstance,
  DomainTypeEnum,
  ProductName,
  RoleSource,
} from '@pypestream/api-services';
import {
  Account,
  CreateAccountMutation,
  CreateAccountMutationVariables,
  CreateAppletInstanceMutation,
  CreateAppletInstanceMutationVariables,
  CreateProjectMutationVariables,
  Environments,
  GetAccountsQuery,
  GetAppletInstancesByProjectIdQuery,
  GetAppletTemplatesQuery,
  GetAuditLogsQuery,
  GetChildAccountsQuery,
  GetLanguagesQuery,
  GetProductsQuery,
  GetProjectsQuery,
  GetTeamProjectsDetailsQuery,
  GetTimeZonesQuery,
  GetUserSettingsQuery,
  GetUsersQuery,
  QueryMetadata,
  SetUserAccountProjectsRolesMutationVariables,
  Team,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  UpdateAppletInstanceMutation,
  UpdateAppletInstanceMutationVariables,
  UpdateUserMutation,
  urqlGql,
  User,
  UserProject,
  UserStatus,
} from '@pypestream/api-services/urql';
import { AutoSaveReturnValue } from '@pypestream/design-system';
import { changeLanguage } from '@pypestream/translations';
import {
  sort,
  transformProfilePicture,
  transformTimezonesData,
} from '@pypestream/utils';
import { assign as immerAssign } from '@xstate/immer';
import { compact, flatten, isEqual, sortBy, uniq } from 'lodash';
import { TreeUtils } from 'simple-tree-utils';
import { assign, Interpreter, Machine, Sender, Typestate } from 'xstate';

import { MyAccountFormValues } from '../hooks';
import {
  getProducts,
  GetProducts,
  haveErrors,
  initialTools,
  Product,
} from '../utils';
import { NotFoundError } from '../utils/errors';
import {
  globalAppService,
  sendManagerEvent,
  sendUserEvent,
} from './app.xstate';
import {
  GLOBAL_APP_SUB_MACHINE_IDS,
  GlobalAppContext,
  sendUserMessage,
  SharedEvents,
} from './app.xstate-utils';
import {
  AccountProjects,
  AccountTeams,
  AgentAssistRoleNames,
  AnalyticsRoleNames,
  handleAddProjectAccess,
  hasPendingUpdates,
  OrgRowSpanCalculatorType,
  processTeamInheritedDetails,
  ProjectProductRoles,
  removeTeam,
  ShortOrgType,
  updateProjectProductRole,
  UserAccountProject,
  UserDetailsType,
  userDetailUpdateInitialState,
  UserDetailUpdates,
  UserTeams,
} from './user-details-xstate-helper';
import { UserInterpreter } from './user.xstate';
import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';

// @todo: start moving these pre-formatted types into the GraphQL package (API services)
export type Language = NonNullable<
  NonNullable<NonNullable<GetLanguagesQuery['admin_']>>['locales']
>[0];

export type LocalizationSettingsConfig = NonNullable<
  GetLanguagesQuery['admin_']
>['localizationSettingsConfig'];

export type Languages = Language[];

export type Timezone = NonNullable<
  NonNullable<NonNullable<GetTimeZonesQuery['admin_']>>['timeZones']
>[0] & {
  searchKeys?: string[];
  originalIdentifier?: string;
};

export type Timezones = Timezone[];

const treeUtils = new TreeUtils({
  idProp: 'id', // the key of a unique identifier for an object (source object)
  parentIdProp: 'parentId', // the key of a unique parent identifier (source object)
  childrenProp: 'children', // the key, where child nodes are stored (destination object tree)
});

export type Org = NonNullable<
  NonNullable<
    NonNullable<NonNullable<GetAccountsQuery>['admin_']>['currentUser']
  >['defaultAccount']
> & {
  selected?: boolean;
  children?: Org[];
  depth?: number;
};

export type OrgWithChildren = Org & {
  children: [];
};

export type AgentSettings = {
  enabled: boolean;
  agentAssist?: {
    accountId?: number;
  };
};

type ProjectSettingsUpdated = {
  productId?: string;
  projectId?: string;
};

export type Project = Omit<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<NonNullable<GetProjectsQuery['admin_']>['projects']>['rows']
      >[0]
    >
  >,
  'projectProductSettings'
> & {
  projectProductSettings?: ProjectSettingsUpdated[];
};

type ProductRoles = NonNullable<
  NonNullable<GetUserSettingsQuery['admin_']>['userProductRoles']
>;

type UserProductRoles = {
  hasAgentAssistRoles?: ProductRoles['hasAgentAssistRoles'];
  hasAnalyticsRoles?: ProductRoles['hasAnalyticsRoles'];
  hasOrganizationRoles?: ProductRoles['hasOrganizationRoles'];
  agentAssistRolesSource?: ProductRoles['agentAssistRolesSource'];
  analyticsRolesSource?: ProductRoles['analyticsRolesSource'];
  organizationRolesSource?: ProductRoles['organizationRolesSource'];
};

export type UserAssignedRole = {
  id?: string;
  name?: string;
};

export interface ManagerContext {
  orgs?: Omit<Org, 'allChildAccounts'>[];
  orgTree?: Org[];

  errors?: Error[];

  userInfo: {
    id?: User['id'];
    // kratosId?: string;
    defaultOrgId?: Account['id'];
    email?: User['userName'];
    productRoles?: UserProductRoles;
    recommendedAuthMethod?: string;
    registeredAuthMethods?: string[];
    isPypestreamEmployee?: boolean;
    status?: User['status'];
    canManageAccess?: boolean;
    defaultLanguage?: string;
  };

  products?: NonNullable<GetProductsQuery['admin_']>['allToolProducts'];
  allProducts?: NonNullable<GetProductsQuery['admin_']>['allProducts'];

  selectedOrgId?: Account['id'];
  selectedProjectModal?: Project['projectId'];

  selectedProject?: Project['projectId'];

  tools: Product[];
  projects?: (Project & {
    selectedProjectURL?: string;
    conditionalProducts?: Product[];
    hasAvailableProduct?: boolean;
  })[];

  applets?: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<GetAppletInstancesByProjectIdQuery['admin_']>['projectById']
      >['projectEnvironmentConfig']
    >[number]['appletInstances']
  >;

  userProjectIds: string[];
  createProjectTools?: Product[];

  getProducts: GetProducts;

  routes: {
    home: string;
    myAccount: string;
    teams: string;
    roles: string;
    projects: string;
    orgs: string;
    logs: string;
    admin: string;
    superAdmin: string;
    users: string;
    wipTeams: string;
  };
  appletTemplates: NonNullable<GetAppletTemplatesQuery['admin_']>['applets'];

  languages: Languages;
  timezones: Timezones;
  localizationSettingsConfig: LocalizationSettingsConfig;

  users: NonNullable<NonNullable<GetUsersQuery['admin_']>['users']>['rows'];
  userDetails: {
    [keys: string]: {
      state: UserDetailsType | null;
      userDetailUpdates: UserDetailUpdates;
      initialState: UserDetailsType | null;
    };
  };
  selectedUser?: User['id'];
  teams: (Pick<Team, 'id' | 'name' | 'description' | 'updatedAt'> & {
    assignedMembers?: Pick<
      NonNullable<Team['assignedMembers']>[0],
      'id' | 'firstName' | 'lastName' | 'picture'
    >[];
  })[];
  selectedTeam?: Team['id'];
  featureFlags?: GlobalAppContext['featureFlags'];
  logs: NonNullable<NonNullable<GetAuditLogsQuery['admin_']>['auditLogs']>;
  logsCache: Record<
    string,
    NonNullable<NonNullable<GetAuditLogsQuery['admin_']>['auditLogs']>
  >;
  childOrgs: {
    rows?: NonNullable<
      NonNullable<GetChildAccountsQuery['admin_']>['accounts']
    >['rows'];
    count?: number;
    currentOrg?: NonNullable<GetChildAccountsQuery['admin_']>['accountById'];
  };
  selectedChildOrg?: Account['id'];
  selectedEnvironment?: Environments['id'];
}

export interface ManagerState {
  states: {
    orgRelated: {
      states: {
        idle: Record<string, unknown>;
        checkingUserInfo: Record<string, unknown>;
        ready: {
          states: {
            currentOrg: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            orgs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
                adding: Record<string, unknown>;
                updating: Record<string, unknown>;
                deleting: Record<string, unknown>;
                added: Record<string, unknown>;
                updated: Record<string, unknown>;
                deleted: Record<string, unknown>;
              };
            };
            currentProject: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            projects: {
              states: {
                waitingForOrg: Record<string, unknown>;
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                transforming: Record<string, unknown>;
                deleting: Record<string, unknown>;
                adding: Record<string, unknown>;
                transformAfterDelay: Record<string, unknown>;
                updating: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
              };
            };

            applets: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                deleting: Record<string, unknown>;
                adding: Record<string, unknown>;
                updating: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
                transformAfterDelay: Record<string, unknown>;
              };
            };

            userInfo: {
              states: {
                idle: Record<string, unknown>;
                fetching: Record<string, unknown>;
                refetchUserSettings: Record<string, unknown>;
                refetched: Record<string, unknown>;
                updating: Record<string, unknown>;
                updated: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };

            routes: {
              states: {
                idle: Record<string, unknown>;
                updating: Record<string, unknown>;
                updated: Record<string, unknown>;
              };
            };
            projectDetails: {
              states: {
                idle: Record<string, unknown>;
                name: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                description: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                timeZoneId: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                projectIcon: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
              };
            };
            currentUser: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            users: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            currentTeam: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            teams: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            logs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            childOrgs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            currentEnvironment: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            userDetails: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                addTeamToOrg: Record<string, unknown>;
                autoSaveUserDetails: Record<string, unknown>;
                saveUpdates: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
          };
        };
      };
    };
    global: {
      states: {
        appletTemplates: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        languages: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        timezones: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        products: {
          states: {
            idle: Record<string, unknown>;
            loading: Record<string, unknown>;
            error: Record<string, unknown>;
            loaded: Record<string, unknown>;
          };
        };
      };
    };
    superAdmin: {
      states: {
        idle: Record<string, unknown>;
        dataBaseSnapshot: {
          states: {
            loading: Record<string, unknown>;
            error: Record<string, unknown>;
            loaded: Record<string, unknown>;
          };
        };
      };
    };
  };
}

export type ManagerEvents =
  | SharedEvents
  | {
      type: 'reset.errors';
    }
  | {
      type: 'loggedIn';
    }
  | {
      type: 'reset.context';
      data: ManagerContext;
    }
  | {
      type: 'manager.updateUserInfo';
      data: ManagerContext['userInfo'] & {
        projectIds: string[];
      };
    }
  | {
      type: 'manager.selectProjectModal';
      id: ManagerContext['selectedProjectModal'];
    }
  | {
      type: 'manager.deselectProjectModal';
    }
  | {
      type: 'manager.deleteProject';
      projectId: UserProject['projectId'];
    }
  | {
      type: 'manager.selectProject';
      id: UserProject['projectId'];
    }
  | ({
      type: 'manager.addProject';
      callback?: (res: boolean) => void;
    } & Omit<CreateProjectMutationVariables, 'accountId'>)
  | ({
      type: 'manager.addOrg';
      callback?: (res: boolean) => void;
    } & Omit<CreateAccountMutationVariables, 'parentAccountId'>)
  | ({
      type: 'manager.updateOrg';
      callback?: (res: boolean) => void;
    } & Omit<UpdateAccountMutationVariables, 'id'>)
  | ({
      type: 'manager.deleteOrg';
      callback?: (res: boolean) => void;
    } & Pick<Account, 'id'>)
  | ({
      type: 'manager.addApplet';
    } & CreateAppletInstanceMutationVariables)
  | ({
      type: 'manager.updateApplet';
    } & UpdateAppletInstanceMutationVariables)
  | ({
      type: 'manager.deleteApplet';
    } & Pick<AppletInstance, 'id'>)
  | {
      type: 'manager.updateProject';
      projectId: UserProject['projectId'];
      name?: string;
      description?: string;
      timeZoneId?: string;
      productIds?: string[];
      projectIcon?: string;
      environmentConfig?: {
        domains: {
          type: DomainTypeEnum;
          url: string;
        }[];
        description: string;
        name: string;
      }[];
    }
  | {
      type:
        | 'manager.updateProject.name'
        | 'manager.updateProject.description'
        | 'manager.updateProject.projectIcon'
        | 'manager.updateProject.productIds'
        | 'manager.updateProject.timeZoneId';
      projectId: UserProject['projectId'];
      data: AutoSaveReturnValue;
    }
  | {
      type: 'manager.updateProject.environmentConfig';
      projectId: UserProject['projectId'];
      environmentConfig?: {
        domains: {
          type: DomainTypeEnum;
          url: string;
        }[];
        description: string;
        name: string;
      }[];
    }
  | {
      type: 'manager.selectOrgId';
      orgId: ManagerContext['selectedOrgId'];
    }
  | {
      type: 'manager.updateUserPreferences';
      values: MyAccountFormValues;
    }
  | {
      type: 'manager.refetchUserSettings';
      orgId: ManagerContext['selectedOrgId'];
    }
  | {
      type: 'manager.transformProjectsSettings';
    }
  | {
      type: 'manager.createDbSnapshot';
    }
  | {
      type: 'manager.users.loadUsers';
    }
  | {
      type: 'manager.selectUser';
      id?: User['id'];
    }
  | {
      type: 'manager.teams.loadTeams';
    }
  | {
      type: 'manager.selectTeam';
      id: Team['id'];
    }
  | {
      type: 'manager.setFeatureFlags';
      values: GlobalAppContext['featureFlags'];
    }
  | { type: 'manager.logs.loadLogs'; queryMetadata: QueryMetadata }
  | {
      type: 'manager.childOrgs.loadChildOrgs';
    }
  | {
      type: 'manager.selectChildOrg';
      id: Account['id'];
    }
  | {
      type: 'manager.selectEnvironment';
      id: Environments['id'];
    }
  | {
      type: 'manager.loadUserDetails';
      userId: User['id'];
      orgId: Account['id'];
    }
  | {
      type: 'manager.autoSaveUserDetails';
    }
  | {
      type: 'manager.userDetails.addProject';
      project: NonNullable<AccountProjects>[number];
    }
  | {
      type: 'manager.userDetails.updateProjectProductRole';
      projectId: string;
      orgId: string;
      role: NonNullable<ProjectProductRoles>[number];
      operation: 'add' | 'remove' | 'update';
    }
  | {
      type: 'manager.userDetails.removeProject';
      projectId: string;
      orgId: string;
    }
  | {
      type: 'manager.userDetails.addTeam';
      team: NonNullable<AccountTeams>[number];
      orgId: string;
    }
  | {
      type: 'manager.userDetails.removeTeam';
      team: UserTeams[number];
      orgId: string;
    }
  | {
      type: 'manager.userDetails.setStateToIdle';
    }
  | {
      type: 'manager.userDetails.discardUpdates';
      userId: User['id'];
    }
  | {
      type: 'manager.userDetails.saveUpdates';
      userId: User['id'];
      orgId: Account['id'];
    };

export interface ManagerTypestates extends Typestate<ManagerContext> {
  context: ManagerContext;
  value: MachineStateSchemaPaths<ManagerState['states']>;
}

export type ManagerInterpreter = Interpreter<
  ManagerContext,
  ManagerState,
  ManagerEvents,
  ManagerTypestates
>;

const { createInvokablePromise, createXstateHooks: createManagerXstateHooks } =
  getXstateUtils<
    ManagerContext,
    ManagerEvents,
    ManagerTypestates,
    ManagerState
  >();

export { createManagerXstateHooks };

// @todo: figure out why we can't easily (fully) cancel the subscription after running just once
let hasUpdateUserInfoSubRun = false;

export const managerMachine = Machine<
  ManagerContext,
  ManagerState,
  ManagerEvents
>({
  id: 'manager',
  predictableActionArguments: true,
  preserveActionOrder: true,
  invoke: {
    id: 'managerObserver',
    src: (ctx) => async (sendEvent: Sender<ManagerEvents>) => {
      // remove globalAppService subscription and featureFlags from manager context when managerProjectIdForContactCenter will be removed
      globalAppService.subscribe(async (state) => {
        const globalContext = state?.context;

        if (!globalContext) return;

        const { featureFlags } = globalContext;

        sendEvent({
          type: 'manager.setFeatureFlags',
          values: featureFlags,
        });
      });

      const userService = globalAppService.children.get(
        GLOBAL_APP_SUB_MACHINE_IDS.user
      ) as UserInterpreter;

      const userServiceSubscription = userService?.subscribe(async (state) => {
        const userContext = state?.context;

        const id = userContext?.user?.id;
        if (!id) return;

        if (hasUpdateUserInfoSubRun === false) {
          hasUpdateUserInfoSubRun = true;
          const defaultOrgId = userContext?.user?.defaultAccount?.defaultOrgId;
          const email = userContext?.user?.email;
          const status = userContext?.user?.status;
          const projectIdsAssignedThroughTeams = compact(
            userContext?.user?.assignedTeams
              ?.map(({ assignedProjects }) =>
                assignedProjects?.map((assignedProject) => assignedProject.id)
              )
              .flat()
          );

          sendEvent({
            type: 'manager.updateUserInfo',
            data: {
              ...ctx?.userInfo,
              id,
              // kratosId,
              defaultOrgId,
              email,
              status,
              isPypestreamEmployee:
                email?.endsWith('@pypestream.com') ||
                email?.endsWith('@pypestream.mailisk.net'),
              projectIds: projectIdsAssignedThroughTeams,
            },
          });
        }
      });

      if (hasUpdateUserInfoSubRun) {
        userServiceSubscription.unsubscribe();
      }
    },
  },
  context: {
    userInfo: {},
    selectedOrgId: undefined,
    projects: [],
    userProjectIds: [],
    errors: [],
    tools: initialTools,
    getProducts,
    routes: {
      home: '/',
      myAccount: '/my-account',
      users: '/users',
      teams: '/teams-management',
      roles: '/roles-management',
      projects: '/projects',
      orgs: '/child-orgs',
      logs: '/audit-logs',
      superAdmin: '/super-admin',
      wipTeams: '/teams',
      admin: import.meta.env.FE_ADMIN_URL,
    },
    appletTemplates: [],
    languages: [],
    localizationSettingsConfig: undefined,
    timezones: [],
    users: [],
    teams: [],
    logs: {
      count: 0,
      rows: [],
    },
    logsCache: {},
    childOrgs: {
      currentOrg: undefined,
      rows: [],
      count: 0,
    },
    userDetails: {},
  },
  type: 'parallel',
  on: {
    loggedIn: {
      actions: assign((ctx) => {
        console.log('logged in!');
        // ctx.selectedOrgId = orgId;
        return ctx;
      }),
    },
    'manager.selectProject': {
      actions: assign((ctx, { id }) => {
        ctx.selectedProject = id;
        return ctx;
      }),
      cond: (ctx, event) =>
        event.type === 'manager.selectProject' &&
        event.id !== ctx.selectedProject,
    },
    'manager.updateUserInfo': {
      target: '#checkingUserInfo',
      actions: assign((ctx, { data: { projectIds, ...userInfo } }) => {
        ctx.userInfo = userInfo;
        ctx.userProjectIds = projectIds;
        return ctx;
      }),
    },
    'manager.transformProjectsSettings': {
      target: '#projects.transforming',
    },

    'manager.createDbSnapshot': {
      target: '#dataBaseSnapshot.loading',
    },
    'manager.updateProject.name': {
      target: '#projectNameUpdating',
    },
    'manager.updateProject.description': {
      target: '#projectDescriptionUpdating',
    },
    'manager.updateProject.timeZoneId': {
      target: '#projectTimeZoneIdUpdating',
    },
    'manager.updateProject.projectIcon': {
      target: '#projectIconUpdating',
    },
    'manager.selectUser': {
      actions: assign((ctx, { id }) => {
        ctx.selectedUser = id;
        return ctx;
      }),
    },
    'manager.selectTeam': {
      actions: assign((ctx, { id }) => {
        ctx.selectedTeam = id;
        return ctx;
      }),
    },
    'manager.setFeatureFlags': {
      actions: assign((ctx, { values }) => {
        ctx.featureFlags = values;
        return ctx;
      }),
    },
    'manager.selectChildOrg': {
      actions: assign((ctx, { id }) => {
        ctx.selectedChildOrg = id;
        return ctx;
      }),
    },
    'manager.selectEnvironment': {
      actions: assign((ctx, { id }) => {
        ctx.selectedEnvironment = id;
        return ctx;
      }),
    },
  },
  states: {
    orgRelated: {
      type: 'compound',
      initial: 'idle',
      states: {
        idle: {
          id: 'idle',
        },
        checkingUserInfo: {
          id: 'checkingUserInfo',
          always: [
            {
              target: 'ready',
              cond: (context, event) => context.userInfo?.id !== undefined,
            },
            {
              target: 'idle',
            },
          ],
        },
        ready: {
          id: 'ready',
          type: 'parallel',
          states: {
            currentOrg: {
              id: 'currentOrg',
              initial: 'notSelected',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx, { orgId }) => {
                    ctx.selectedOrgId = orgId || ctx.userInfo.defaultOrgId;
                    ctx.selectedProjectModal = undefined;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedOrgId) return;

                      const { data, error } = await urqlGql.getAccountById({
                        id: ctx.selectedOrgId,
                      });

                      if (!data?.admin_?.accountById) {
                        throw new NotFoundError('Organization not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            orgs: {
              initial: 'idle',
              id: 'orgs',
              on: {
                'manager.selectOrgId': {
                  target: '#orgLoading',
                },
              },
              states: {
                idle: {
                  // entry: () => {
                  //   console.log('ready to load org info!');
                  // },
                  always: {
                    target: 'loading',
                    cond: (context, event) =>
                      context.userInfo?.id !== undefined,
                  },
                },
                loading: {
                  id: 'orgLoading',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | ManagerContext['orgs']> => {
                      const { data, error } = await urqlGql.getAccounts();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      let orgs: Omit<Org, 'allChildAccounts'>[] = [];

                      const defaultAccount: Org | undefined =
                        data?.admin_?.currentUser?.defaultAccount;

                      if (defaultAccount) {
                        const { allChildAccounts, ...orgProps } =
                          defaultAccount;
                        orgs = [orgProps].concat(allChildAccounts || []);
                      }

                      return orgs;
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.orgs = data;

                        const orgsWithChildren: OrgWithChildren[] =
                          treeUtils.list2Tree(data) as OrgWithChildren[];

                        orgsWithChildren.map((foo) => {
                          if ('id' in foo) {
                            foo.selected = foo?.id === ctx.selectedOrgId;
                          }

                          if ('children' in foo === undefined) {
                            foo.children = [];
                          }

                          return foo;
                        }) as OrgWithChildren[];

                        // console.log(orgsWithChildren);

                        const maxDepth = (root: OrgWithChildren) => {
                          // if root is undefined, depth is 0
                          if (!root) return 0;
                          // variable to store the maximum levels
                          let max = 0;
                          // helper function to traverse the tree
                          // recursively increment the levels by one
                          const dfs = (
                            node: OrgWithChildren,
                            levels: number
                          ) => {
                            // console.log(node.name, levels);

                            node.depth = levels;
                            // compare levels and max to pass the maximum levels
                            if (levels > max) max = levels;
                            // traverse all children of the current node
                            for (const child of node.children) {
                              // increment the levels by one
                              dfs(child, levels + 1);
                            }
                          };
                          // when root is not null, the tree has at least one level,
                          // so we pass down 1
                          dfs(root, 1);

                          // return the maximum levels
                          return max;
                        };

                        maxDepth(orgsWithChildren[0]);

                        ctx.orgTree = orgsWithChildren;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                loaded: {
                  on: {
                    'manager.addOrg': {
                      target: 'adding',
                    },
                    'manager.updateOrg': {
                      target: 'updating',
                    },
                    'manager.deleteOrg': {
                      target: 'deleting',
                    },
                  },
                },
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      account: NonNullable<
                        CreateAccountMutation['admin_']
                      >['createAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addOrg' ||
                        !event.accountName ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        accountName,
                        picture,
                        accountManagerId,
                        securitySettings,
                        accountIconId,
                      } = event;

                      const { data, error } = await urqlGql.createAccount({
                        parentAccountId: ctx.selectedOrgId,
                        accountName,
                        picture,
                        accountManagerId,
                        securitySettings,
                        accountIconId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        account: data?.admin_?.createAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data || !data.account) {
                        throw new Error(
                          'Returned data from createAccount is undefined'
                        );
                      }
                      ctx.orgs?.push(data.account);
                      if (data.callback) {
                        data.callback(true);
                      }
                    },
                    onDoneTarget: 'added',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                added: {
                  after: {
                    100: 'loaded',
                  },
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      updateAccount: NonNullable<
                        UpdateAccountMutation['admin_']
                      >['updateAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.updateOrg' ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        name,
                        picture,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                        accountIconId,
                      } = event;
                      const { data, error } = await urqlGql.updateAccount({
                        id: ctx.selectedOrgId,
                        name,
                        picture,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                        accountIconId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        updateAccount: data?.admin_?.updateAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined && !!data.updateAccount) {
                        const index = ctx.orgs?.findIndex(
                          (a) => a.id === data.updateAccount?.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.orgs = ctx.orgs?.with(index, data.updateAccount);
                          if (data.callback) {
                            data.callback(true);
                          }
                        }
                        // update childOrgs context
                        if (
                          ctx.childOrgs.currentOrg &&
                          ctx.childOrgs.currentOrg?.id === data.updateAccount.id
                        ) {
                          ctx.childOrgs.currentOrg.name =
                            data.updateAccount.name;
                          ctx.childOrgs.currentOrg.pictureFile =
                            data.updateAccount.pictureFile;
                        }
                      }
                    },
                    onDoneTarget: 'updated',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating org's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Organization ${data?.updateAccount?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  after: {
                    100: 'loaded',
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      id: Account['id'];
                      callback: ((res: boolean) => void) | undefined;
                    } | void> => {
                      if (event.type !== 'manager.deleteOrg' || !event.id)
                        return;

                      if (!ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      if (ctx.userInfo.defaultOrgId === ctx.selectedOrgId) {
                        throw new Error('cannot delete default organization!');
                      }

                      const { data, error } = await urqlGql.deleteAccount({
                        id: event.id,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return {
                        id: event.id,
                        callback: event.callback,
                      };
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `The organization deleted successfully`,
                            autoClose: 3000,
                          });
                          if (data && data.callback) {
                            data.callback(true);
                          }
                        },
                      },
                    ],
                    onDoneTarget: 'deleted',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                deleted: {
                  after: {
                    100: 'loaded',
                  },
                },
              },
            },
            currentProject: {
              id: 'currentProject',
              initial: 'notSelected',
              on: {
                'manager.selectProject': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedProject = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedProject) return;

                      const { data, error } = await urqlGql.getProjectById({
                        id: ctx.selectedProject,
                      });

                      if (!data?.admin_?.projectById) {
                        throw new NotFoundError('Project not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            projects: {
              id: 'projects',
              initial: 'waitingForOrg',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx, { orgId }) => {
                    ctx.projects = [];
                    ctx.userProjectIds = [...ctx.userProjectIds];
                    return ctx;
                  }),
                  target: '#loadingProjects',
                },
              },
              states: {
                waitingForOrg: {
                  on: {
                    'manager.selectOrgId': {
                      target: 'idle',
                    },
                  },
                },
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: '#loadingProjects',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedOrgId !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            ctx.userInfo?.productRoles &&
                            ctx.products?.length
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingProjects',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      projects: ManagerContext['projects'];
                      userProjectIds: string[];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedOrgId === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } = await urqlGql.getProjects({
                        userId: ctx.userInfo.id,
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allProjects = compact(data?.admin_?.projects?.rows);
                      const userProjectIds = compact(
                        data?.admin_?.userProjects?.rows
                      );

                      return {
                        projects: allProjects,
                        userProjectIds: userProjectIds.length
                          ? uniq(
                              compact(
                                userProjectIds
                                  .map((item) => item.project?.id)
                                  .concat(ctx.userProjectIds)
                              )
                            )
                          : [...ctx.userProjectIds],
                      };
                    },
                    onDoneTarget: '#transforming',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userProjectIds = data.userProjectIds;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      userInfo: ManagerContext['userInfo'];
                      newProjectId: string | undefined;
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addProject' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        timeZoneId,
                        name,
                        productIds,
                        description,
                        projectEnvironmentConfigs,
                        projectIconId,
                      } = event;

                      const { data, error } = await urqlGql.createProject({
                        name,
                        accountId: ctx.selectedOrgId,
                        productIds: productIds || [],
                        description: description || '',
                        timeZoneId: timeZoneId || undefined,
                        projectEnvironmentConfigs,
                        projectIconId: projectIconId
                          ? transformProfilePicture(projectIconId)
                          : '',
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (data?.admin_?.createProject === undefined) {
                        return {
                          projects: ctx.projects,
                          userInfo: ctx.userInfo,
                          newProjectId: undefined,
                          callback: event.callback,
                        };
                      }

                      const projects: ManagerContext['projects'] = [
                        {
                          // __typename: 'UserProject',
                          // accountId: ctx.selectedOrgId,
                          // project: data.admin_.createProject,
                          projectId: data.admin_.createProject.id,
                          accountId: data.admin_.createProject.accountId,
                          name: data.admin_.createProject.name,
                          description: data.admin_.createProject.description,
                          picture: data.admin_.createProject.picture,
                          updatedAt: new Date().toISOString(),
                          createdAt: new Date().toISOString(),
                          pictureFile: data.admin_.createProject.pictureFile,
                          projectProductSettings:
                            data.admin_.createProject.projectProductSettings,
                          projectEnvironmentConfig:
                            data.admin_.createProject.projectEnvironmentConfig,
                          localizationSettings:
                            data.admin_.createProject.localizationSettings,
                          users: data.admin_.createProject.users,
                        },
                        ...(ctx.projects || []),
                      ];

                      const products = ctx.getProducts({
                        ctx,
                      });

                      const projectProducts =
                        products.conditionalProducts.filter((product) =>
                          productIds.includes(product.productId)
                        );

                      let userInformation = ctx.userInfo;
                      const userMainOrgId = ctx.orgTree?.[0]?.id;

                      // update userInfo with new project for agentAssist product
                      if (
                        projectProducts.find(
                          (product) => product.name === 'AGENT_ASSIST'
                        ) &&
                        userInformation.productRoles?.agentAssistRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            agentAssistRolesSource: [
                              ...userInformation.productRoles
                                .agentAssistRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      // update userInfo with new project for analytics product
                      if (
                        projectProducts.find(
                          (product) => product.name === 'ANALYTICS'
                        ) &&
                        userInformation.productRoles?.analyticsRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            analyticsRolesSource: [
                              ...userInformation.productRoles
                                .analyticsRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      return {
                        projects,
                        userInfo: userInformation,
                        newProjectId: data.admin_.createProject.id,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userInfo = data.userInfo;

                        if (
                          data.newProjectId &&
                          data.newProjectId !== undefined
                        ) {
                          ctx.userProjectIds.push(data.newProjectId);
                        }

                        if (data.callback) {
                          data.callback(true);
                        }
                      }
                    },
                    onDoneTarget: '#transformProjectsAfterDelay',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                transformAfterDelay: {
                  id: 'transformProjectsAfterDelay',
                  after: {
                    50: {
                      target: 'loading',
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      event: ManagerEvents;
                      project?: Project;
                    }> => {
                      if (event.type !== 'manager.deleteProject') return;

                      if (!event.projectId || !ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      const { data, error } = await urqlGql.deleteProject({
                        projectId: event.projectId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const deletedProject = ctx.projects?.find(
                        (project) => project.projectId === event.projectId
                      );
                      const filteredProjects = ctx.projects?.filter(
                        (project) => project.projectId !== event.projectId
                      );

                      return {
                        projects: filteredProjects,
                        event,
                        project: deletedProject,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                      }
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          if (data?.event?.type !== 'manager.deleteProject')
                            return;

                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data.project?.name} deleted successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                  }),
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      nextProjectsState: ManagerContext['projects'];
                      updatedProject?: Project;
                    }> => {
                      if (event.type !== 'manager.updateProject') return;

                      if (event.projectId === undefined) return;

                      const { data, error } = await urqlGql.updateProject({
                        projectId: event.projectId,
                        description: event.description || '',
                        name: event.name || '',
                        timeZoneId: event.timeZoneId || undefined,
                        productIds: event.productIds || [],
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (
                        ctx.projects?.length === 0 ||
                        ctx.projects === undefined
                      ) {
                        return {
                          nextProjectsState: [],
                          updatedProject: undefined,
                        };
                      }

                      const updateChannels = Promise.all(
                        (event.environmentConfig || []).map(
                          async ({ name, description, domains }) => {
                            const projectEnvironmentConfigId =
                              ctx.projects
                                ?.find(
                                  ({ projectId }) =>
                                    projectId === event.projectId
                                )
                                ?.projectEnvironmentConfig?.find(
                                  ({ environment }) => {
                                    return environment?.name === name;
                                  }
                                )?.id || '';

                            const result =
                              await urqlGql.updateProjectEnvironmentConfig({
                                projectId: event.projectId || '',
                                description,
                                domains,
                                projectEnvironmentConfigId,
                              });

                            return result;
                          }
                        )
                      );

                      const result = await updateChannels;

                      result.map(
                        ({ data: channelsData, error: channelsError }) => {
                          if (haveErrors(channelsData)) {
                            throw new Error(channelsData.errors?.[0]?.message);
                          }

                          if (channelsError) {
                            throw new Error(channelsError.message);
                          }
                        }
                      );

                      const updatedEnvironmentConfigs = result.map(
                        (r) => r.data?.admin_?.updateProjectEnvironmentConfig
                      );

                      const updatedProject = data?.admin_?.updateProject;

                      const nextProjectsState = ctx.projects.map((item) => {
                        if (item.projectId === updatedProject?.projectId) {
                          return {
                            ...item,
                            ...updatedProject,
                            ...(event.environmentConfig?.length && {
                              projectEnvironmentConfig:
                                item.projectEnvironmentConfig?.map((config) => {
                                  const { environmentId, environment } = config;
                                  const updatedConfig =
                                    updatedEnvironmentConfigs?.find(
                                      (c) => c?.environmentId === environmentId
                                    );

                                  return {
                                    ...updatedConfig,
                                    environmentId,
                                    environment,
                                  };
                                }),
                            }),
                          };
                        }
                        return item;
                      });

                      return { nextProjectsState, updatedProject };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.nextProjectsState;
                      }
                    },
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data?.updatedProject?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transforming: {
                  id: 'transforming',
                  entry: assign((ctx) => {
                    const projectsWithAvailableProduct =
                      ctx.projects?.filter(
                        ({ projectProductSettings }) =>
                          projectProductSettings?.length
                      ) || [];

                    const projectWithAvailableProduct =
                      projectsWithAvailableProduct.length === 1
                        ? projectsWithAvailableProduct[0]
                        : undefined;

                    const projectWithAvailableAgentAssistProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const agentAssistRolesSource =
                          ctx.userInfo?.productRoles?.agentAssistRolesSource ||
                          [];

                        return agentAssistRolesSource.length === 1
                          ? agentAssistRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableAnalyticsProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const analyticsRolesSource =
                          ctx.userInfo?.productRoles?.analyticsRolesSource ||
                          [];

                        return analyticsRolesSource.length === 1
                          ? analyticsRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableStudioProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const studioRolesSource: RoleSource[] = [];
                        // @todo: currently no studioRolesSource in the userProductRoles
                        // probably fuctionality will be different
                        // ctx.userInfo?.productRoles?.studioRolesSource || [];

                        return studioRolesSource.length === 1
                          ? studioRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const tools = [
                      projectWithAvailableProduct,
                      projectWithAvailableAgentAssistProduct,
                      projectWithAvailableAnalyticsProduct,
                      projectWithAvailableStudioProduct,
                    ].map((projectWithProduct, index) => {
                      const { products: _tools } = ctx.getProducts({
                        project: projectWithProduct,
                        ctx,
                      });

                      return _tools[index];
                    });

                    const projects = ctx.projects?.map((p) => {
                      const {
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      } = ctx.getProducts({
                        project: p,
                        ctx,
                      });

                      return {
                        ...p,
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      };
                    });

                    const { conditionalProducts: createProjectTools } =
                      ctx.getProducts({ ctx });

                    return {
                      ...ctx,
                      tools,
                      projects,
                      createProjectTools,
                    };
                  }),
                  always: {
                    cond: (ctx) => Boolean(ctx.tools && ctx.createProjectTools),
                    target: '#projectsLoaded',
                  },
                },
                loaded: {
                  id: 'projectsLoaded',
                  on: {
                    'manager.deleteProject': 'deleting',
                    'manager.addProject': 'adding',
                    'manager.updateProject': 'updating',
                    'manager.selectProjectModal': {
                      actions: assign((ctx, { id }) => {
                        ctx.selectedProjectModal = id;
                        return ctx;
                      }),
                    },
                    'manager.deselectProjectModal': {
                      actions: assign((ctx) => {
                        ctx.selectedProjectModal = undefined;
                        return ctx;
                      }),
                    },
                  },
                },
              },
            },

            applets: {
              id: 'applets',
              initial: 'idle',
              states: {
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: 'loading',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedProject !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            !ctx.applets
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingApplets',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      applets: ManagerContext['applets'];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedProject === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } =
                        await urqlGql.getAppletInstancesByProjectId({
                          projectId: ctx.selectedProject,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allAppletInstances =
                        compact(
                          flatten(
                            compact(
                              data?.admin_?.projectById
                                ?.projectEnvironmentConfig
                            )?.map((prcc) => prcc.appletInstances)
                          )
                        ) || [];

                      return {
                        applets: allAppletInstances,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.applets = data.applets;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          CreateAppletInstanceMutation['admin_']
                        >['createAppletInstance']
                    > => {
                      if (
                        event.type !== 'manager.addApplet' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        description,
                        name,
                        projectEnvironmentConfigId,
                        appletVersionId,
                      } = event;

                      const { data, error } =
                        await urqlGql.createAppletInstance({
                          name: name,
                          projectEnvironmentConfigId:
                            projectEnvironmentConfigId,
                          description: description || '',
                          // appletVersionId: event.latestVersion.id,
                          appletVersionId: appletVersionId,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.createAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data) {
                        throw new Error(
                          'Returned data from createAppletInstance is undefined'
                        );
                      }
                      ctx.applets?.push(data);
                    },
                    onDoneTarget: 'transformAfterDelay',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transformAfterDelay: {
                  id: 'transformAppletsAfterDelay',
                  after: {
                    50: {
                      target: 'loaded',
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<AppletInstance['id'] | void> => {
                      if (event.type !== 'manager.deleteApplet' || !event.id)
                        return;

                      const { data, error } =
                        await urqlGql.deleteAppletInstance({
                          deleteAppletInstanceId: event.id,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return event.id;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      ctx.applets = ctx.applets?.filter((a) => a.id !== data);
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          UpdateAppletInstanceMutation['admin_']
                        >['updateAppletInstance']
                    > => {
                      if (event.type !== 'manager.updateApplet') return;

                      if (event.updateAppletInstanceId === undefined) return;

                      const { type, ...variables } = event;
                      const { data, error } =
                        await urqlGql.updateAppletInstance(variables);

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.updateAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        const index = ctx.applets?.findIndex(
                          (a) => a.id === data.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.applets = ctx.applets?.with(index, data);
                        }
                      }
                    },
                    // onDoneTarget: 'idle',
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Applet ${data?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  id: 'AppletsLoaded',
                  on: {
                    'manager.addApplet': 'adding',
                    'manager.deleteApplet': 'deleting',
                    'manager.updateApplet': 'updating',
                  },
                },
              },
            },

            // Shouldn't live in this state machine (instead under User machine); shouldn't require us to pass in the accountId. Even if it did, it should be the accountId under our user account, not the accountId of the currently selected org
            userInfo: {
              id: 'userInfo',
              initial: 'idle',
              states: {
                idle: {
                  always: [
                    {
                      target: 'fetching',
                      cond: (context) =>
                        Boolean(
                          !context.userInfo?.recommendedAuthMethod &&
                            context.userInfo?.id !== undefined &&
                            context.selectedOrgId
                        ),
                    },
                  ],
                  on: {
                    'manager.updateUserPreferences': {
                      target: '#userInfo.updating',
                    },
                    'manager.refetchUserSettings': {
                      target: '#userInfo.fetching',
                    },
                  },
                },
                fetching: {
                  id: 'fetching',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email, defaultOrgId },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: defaultOrgId || selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextUserInfoState: ManagerContext['userInfo'] = {
                        ...ctx.userInfo,
                        status:
                          data?.admin_?.currentUser?.status ||
                          ctx.userInfo.status,
                        productRoles: data?.admin_?.userProductRoles || {},
                        recommendedAuthMethod:
                          data?.admin_?.getLoginInfo?.recommendedAuthMethod,
                        registeredAuthMethods:
                          data?.admin_?.getLoginInfo?.registeredAuthMethods ||
                          [],
                        canManageAccess: Boolean(
                          data?.admin_?.organizationMetadata?.canManageAccess
                        ),
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextUserInfoState.defaultLanguage) {
                        await changeLanguage(nextUserInfoState.defaultLanguage);
                      }

                      return {
                        nextUserInfoState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextUserInfoState;
                      }
                    },
                    onDoneTarget: 'idle',
                    onErrorTarget: 'error',
                    onDoneActions: [
                      {
                        type: 'transformProjectsSettings',
                        exec(
                          _ctx,
                          {
                            data: {
                              event: { type },
                            },
                          }
                        ) {
                          if (type === 'manager.refetchUserSettings') {
                            sendManagerEvent({
                              type: 'manager.transformProjectsSettings',
                            });
                          }
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetchUserSettings: {
                  id: 'refetchUserSettings',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextState = {
                        ...ctx.userInfo,
                        selectedOrgId,
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextState.defaultLanguage) {
                        await changeLanguage(nextState.defaultLanguage);
                      }
                      const userService = globalAppService.children.get(
                        GLOBAL_APP_SUB_MACHINE_IDS.user
                      ) as UserInterpreter;
                      userService.send({
                        type: 'update.userPreferences',
                        userActionPayload: {
                          user: data?.admin_?.currentUser,
                        },
                      });

                      return {
                        nextState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextState;
                      }
                    },
                    onDoneTarget: 'refetched',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetched: {
                  after: {
                    100: 'idle',
                  },
                },
                updating: {
                  id: 'updating',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<UpdateUserMutation['admin_']>['updateUser']
                    > => {
                      if (event.type !== 'manager.updateUserPreferences')
                        return;

                      const {
                        firstName,
                        lastName,
                        profilePhoto: picture,
                        jobTitle,
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                        defaultLanguage,
                        defaultTimezone,
                      } = event.values;

                      const {
                        userInfo: { id: userId },
                        selectedOrgId: accountId,
                      } = ctx;

                      if (!userId || !accountId) {
                        throw new Error('User id or accountId not provided');
                      }

                      // we shouldn't have to re-send values that haven't changed
                      const { data, error } = await urqlGql.updateUser({
                        id: userId,
                        accountId,
                        firstName,
                        lastName,
                        picture: picture
                          ? transformProfilePicture(picture)
                          : '',
                        settings: {
                          jobTitle,
                          defaultLanguage,
                          defaultTimezone,
                        },
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.updateUser;
                    },
                    onDoneTarget: 'updated',
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `User ${data?.firstName} ${data?.lastName} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user info via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  entry() {
                    setTimeout(
                      () => sendUserEvent({ type: 'user.refetch' }),
                      1
                    );
                  },
                  always: [{ target: '#refetchUserSettings' }],
                },
                error: {},
              },
            },

            // shouldn't exist
            routes: {
              id: 'routes',
              initial: 'idle',
              on: {
                'manager.selectOrgId': {
                  target: '#updatingRoutes',
                },
              },
              states: {
                idle: {
                  always: {
                    target: 'updating',
                    cond: (context) => context.selectedOrgId !== undefined,
                  },
                },
                updating: {
                  id: 'updatingRoutes',
                  entry: assign((ctx) => {
                    const { selectedOrgId, routes } = ctx;
                    const { admin: adminBaseURL } = routes;
                    const adminURL = `${adminBaseURL}/organization/${selectedOrgId}`;
                    const basePath = selectedOrgId
                      ? `/organization/${selectedOrgId}`
                      : '';

                    ctx.routes = {
                      ...ctx.routes,
                      home: basePath || '/',
                      myAccount: `${basePath}/my-account`,
                      projects: `${basePath}/projects`,
                      users: `${basePath}/users`,
                      teams: `${adminURL}/teams-management`,
                      roles: `${adminURL}/roles-management`,
                      orgs: `${basePath}/child-orgs`,
                      logs: `${basePath}/audit-logs`,
                      superAdmin: `${basePath}/super-admin`,
                      wipTeams: `${basePath}/teams`,
                    };

                    return ctx;
                  }),
                  onDone: 'updated',
                },
                updated: {
                  on: {
                    'manager.selectOrgId': {
                      target: '#updatingRoutes',
                    },
                  },
                },
              },
            },
            projectDetails: {
              initial: 'idle',
              states: {
                idle: {},
                name: {
                  states: {
                    loading: {
                      id: 'projectNameUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.name')
                            return;

                          if (event.projectId === undefined) return;

                          const { projectId } = event;

                          const { data, error } = await urqlGql.updateProject({
                            projectId,
                            name: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedName = data?.admin_?.updateProject?.name;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  name: updatedName || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successNameUpdating',
                        onErrorTarget: '#errorNameUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's name: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successNameUpdating',
                    },
                    error: {
                      id: 'errorNameUpdating',
                    },
                  },
                },
                description: {
                  states: {
                    loading: {
                      id: 'projectDescriptionUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (
                            event.type !== 'manager.updateProject.description'
                          )
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            description: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedDescription =
                            data?.admin_?.updateProject?.description;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  description: updatedDescription || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successDescriptionUpdating',
                        onErrorTarget: '#errorDescriptionUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's description: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successDescriptionUpdating',
                    },
                    error: {
                      id: 'errorDescriptionUpdating',
                    },
                  },
                },
                timeZoneId: {
                  states: {
                    loading: {
                      id: 'projectTimeZoneIdUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.timeZoneId')
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            timeZoneId: (event.data.value || null)!,
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedTimeZone =
                            data?.admin_?.updateProject?.localizationSettings
                              ?.timeZone;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  localizationSettings: {
                                    ...project.localizationSettings,
                                    id: project.localizationSettings?.id || '',
                                    timeZone: {
                                      ...updatedTimeZone,
                                      id: updatedTimeZone?.id || '',
                                      identifier:
                                        updatedTimeZone?.identifier || '',
                                      label: updatedTimeZone?.label || '',
                                    },
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successTimeZoneIdUpdating',
                        onErrorTarget: '#errorTimeZoneIdUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's timezone: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successTimeZoneIdUpdating',
                    },
                    error: {
                      id: 'errorTimeZoneIdUpdating',
                    },
                  },
                },
                projectIcon: {
                  states: {
                    loading: {
                      id: 'projectIconUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (
                            event.type !== 'manager.updateProject.projectIcon'
                          )
                            return;

                          if (event.projectId === undefined) return;

                          const updatedProjectIcon = event.data.value;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  pictureFile: {
                                    ...project.pictureFile,
                                    url: updatedProjectIcon as string,
                                    publicId: project.pictureFile
                                      ?.publicId as string,
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successProjectIconUpdating',
                      }),
                    },
                    success: {
                      id: 'successProjectIconUpdating',
                    },
                    error: {
                      id: 'errorProjectIconUpdating',
                    },
                  },
                },
              },
            },
            currentUser: {
              id: 'currentUser',
              initial: 'notSelected',
              on: {
                'manager.selectUser': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedUser = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedUser) return;

                      const { data, error } = await urqlGql.getUserById({
                        id: ctx.selectedUser,
                      });

                      if (!data?.admin_?.userById) {
                        throw new NotFoundError('User not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            users: {
              id: 'users',
              initial: 'idle',
              on: {
                'manager.users.loadUsers': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['users'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load users!');
                      }

                      const { data, error } = await urqlGql.getUsers({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.users?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.users = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading users: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.users = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            currentTeam: {
              id: 'currentTeam',
              initial: 'notSelected',
              on: {
                'manager.selectTeam': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedTeam = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedTeam) return;

                      const { data, error } = await urqlGql.getTeamById({
                        id: ctx.selectedTeam,
                      });

                      if (!data?.admin_?.teamById) {
                        throw new NotFoundError('Team not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            teams: {
              id: 'teams',
              initial: 'idle',
              on: {
                'manager.teams.loadTeams': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['teams'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load teams!');
                      }

                      const { data, error } = await urqlGql.getTeams({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.teams?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.teams = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading teams: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.teams = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            logs: {
              initial: 'idle',
              on: {
                'manager.logs.loadLogs': {
                  target: '#loadLogs',
                },
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.logs = {
                      count: 0,
                      rows: [],
                    };
                    return ctx;
                  }),
                  target: '#loadLogs',
                },
              },
              states: {
                idle: {},
                loading: {
                  id: 'loadLogs',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | {
                          data: ManagerContext['logs'] | undefined;
                          queryMetadata: QueryMetadata;
                        }
                      | undefined
                    > => {
                      if (event.type !== 'manager.logs.loadLogs') return;

                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load logs!');
                      }

                      const { queryMetadata } = event;
                      const { logsCache } = ctx;
                      const cacheKey = `${ctx.selectedOrgId}_${queryMetadata.skipPages}_${queryMetadata.pageSize}`;
                      if (logsCache[cacheKey]) {
                        return {
                          data: logsCache[cacheKey],
                          queryMetadata,
                        };
                      }

                      const { data, error } = await urqlGql.getAuditLogs({
                        accountId: ctx.selectedOrgId,
                        queryMetadata,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        data: data?.admin_?.auditLogs,
                        queryMetadata,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data && data.data !== undefined) {
                        const { queryMetadata } = data;
                        ctx.logs = data.data;
                        ctx.logsCache[
                          `${ctx.selectedOrgId}_${queryMetadata.skipPages}_${queryMetadata.pageSize}`
                        ] = data.data;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading logs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
            childOrgs: {
              id: 'childOrgs',
              initial: 'idle',
              on: {
                'manager.childOrgs.loadChildOrgs': {
                  target: '.loading',
                },
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.childOrgs = {
                      rows: [],
                      count: 0,
                      currentOrg: undefined,
                    };
                    return ctx;
                  }),
                  target: '#childOrgsLoading',
                },
              },
              states: {
                idle: {},
                loading: {
                  id: 'childOrgsLoading',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<GetChildAccountsQuery['admin_'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load child orgs!');
                      }

                      const { data, error } = await urqlGql.getChildAccounts({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.childOrgs = {
                          rows: data.accounts?.rows,
                          count: data.accounts?.count,
                          currentOrg: data.accountById,
                        };
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading child orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
            currentEnvironment: {
              id: 'currentEnvironment',
              initial: 'notSelected',
              on: {
                'manager.selectEnvironment': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedEnvironment = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedEnvironment) return;

                      const { data, error } =
                        await urqlGql.getProjectEnvironmentConfigById({
                          id: ctx.selectedEnvironment,
                        });

                      if (!data?.admin_?.projectEnvironmentConfigById) {
                        throw new NotFoundError('Environment not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            userDetails: {
              id: 'usersDetails',
              initial: 'idle',
              on: {
                'manager.loadUserDetails': '.loading',
                'manager.userDetails.setStateToIdle': '.idle',
                'manager.autoSaveUserDetails': '.autoSaveUserDetails',
                'manager.userDetails.addProject': {
                  actions: handleAddProjectAccess,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.addTeam': {
                  target: '.addTeamToOrg',
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.updateProjectProductRole': {
                  actions: updateProjectProductRole,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.removeTeam': {
                  actions: removeTeam,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.discardUpdates': {
                  actions: immerAssign((ctx, event) => {
                    if (ctx.userDetails[event.userId]) {
                      ctx.userDetails[event.userId].state =
                        ctx.userDetails[event.userId].initialState;
                      ctx.userDetails[event.userId].userDetailUpdates =
                        userDetailUpdateInitialState;
                    }
                    return ctx;
                  }),
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.saveUpdates': {
                  target: '.saveUpdates',
                  cond: (ctx, event) =>
                    !!ctx.userDetails[event.userId]?.userDetailUpdates
                      .hasPendingUpdates,
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      userDetails: UserDetailsType;
                      userDetailUpdates: UserDetailUpdates;
                    } | null> => {
                      if (event.type !== 'manager.loadUserDetails') {
                        return null;
                      }

                      return Promise.all([
                        urqlGql.getAccountDetails({ id: event.orgId! }),
                        urqlGql.getUserDetails({
                          id: event.userId,
                        }),
                      ]).then(
                        ([
                          { data: orgDetails, error: orgDetailsError },
                          { data: userAccountDetails, error: userDetailsError },
                        ]) => {
                          if (orgDetailsError || userDetailsError) {
                            throw new Error(
                              `*OrgDetailsError - ${orgDetailsError?.message}. *UserDetailsError - ${userDetailsError?.message}`
                            );
                          }
                          const allOrgs = [];

                          if (
                            orgDetails?.admin_?.accountById?.allChildAccounts
                          ) {
                            const { allChildAccounts, ...rest } =
                              orgDetails.admin_.accountById;
                            // @todo -  fix org ordering (Use treeUtil's treeToList function.)
                            allOrgs.push({ ...rest }, ...allChildAccounts);
                          }

                          if (
                            userAccountDetails?.tenancy_users_by_pk &&
                            userAccountDetails.tenancy_users_by_pk.user_accounts
                              .length > 0
                          ) {
                            const accountRoles: NonNullable<
                              UserDetailsType['orgs'][number]['accountRoles']
                            > =
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0]._user_account_roles;

                            const assignedTeamIds =
                              userAccountDetails.tenancy_users_by_pk?._user_teams.map(
                                (team) => team.team.id
                              );
                            const userPrimaryAccountId =
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0].isPrimaryAccount &&
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0].accountId;

                            const projectRolesUpdate: SetUserAccountProjectsRolesMutationVariables['projectRolesUpdate'] =
                              [];
                            const userOrgs: ShortOrgType[] = allOrgs.map(
                              (org) => {
                                const {
                                  assignedProjects,
                                  orgRowSpan,
                                  assignedProjectIds,
                                } =
                                  userAccountDetails.tenancy_users_by_pk!.user_accounts[0].user_account_projects!.reduce(
                                    (
                                      calculatedDetails: OrgRowSpanCalculatorType,
                                      currentProject: UserAccountProject
                                    ) => {
                                      if (
                                        org.id ===
                                        currentProject.project.accountId
                                      ) {
                                        const assignedProjectRoles =
                                          currentProject._user_account_project_roles
                                            .filter((role) => !role.teams)
                                            .map((role) => role.role.id);

                                        currentProject.project.project_product_settings.forEach(
                                          (product) => {
                                            const availableProductRoles =
                                              orgDetails?.admin_?.accountById?.roles?.rows?.filter(
                                                (role) =>
                                                  role.productId ===
                                                    product.product.id &&
                                                  !assignedProjectRoles.includes(
                                                    role.id!
                                                  )
                                              );
                                            if (
                                              !currentProject.availableProjectRoles
                                            ) {
                                              currentProject.availableProjectRoles =
                                                [];
                                            }

                                            if (availableProductRoles) {
                                              currentProject.availableProjectRoles.push(
                                                ...availableProductRoles
                                              );
                                            }
                                          }
                                        );

                                        const userAccountProjectRoles =
                                          currentProject._user_account_project_roles.reduce(
                                            (accumulatedRoles, currentRole) => {
                                              const sameProductRoleIdx =
                                                accumulatedRoles?.findIndex(
                                                  (roles) =>
                                                    roles.role.productId ===
                                                    currentRole.role.productId
                                                );
                                              const sameProductRole =
                                                accumulatedRoles[
                                                  sameProductRoleIdx
                                                ];

                                              if (
                                                (currentRole.teams || [])
                                                  ?.length === 0 &&
                                                sameProductRoleIdx >= 0
                                              ) {
                                                const existingProjectRoleUpdateIndex =
                                                  projectRolesUpdate.findIndex(
                                                    (project) =>
                                                      project.id ===
                                                      currentProject.project.id
                                                  );
                                                // If role is highest possible role for product, then keep that role assigned and remove other low level roles.
                                                if (
                                                  currentRole.role.name ===
                                                    AgentAssistRoleNames.AGENT_ASSIST_ADMIN ||
                                                  currentRole.role.name ===
                                                    AnalyticsRoleNames.ANALYTICS_ADMIN
                                                ) {
                                                  // make this role available with availableProjectRoles
                                                  currentProject.availableProjectRoles?.push(
                                                    {
                                                      id: sameProductRole.role
                                                        .id,
                                                      name: sameProductRole.role
                                                        .name,
                                                      productId:
                                                        sameProductRole.role
                                                          .productId,
                                                    }
                                                  );
                                                  if (
                                                    existingProjectRoleUpdateIndex >=
                                                    0
                                                  ) {
                                                    projectRolesUpdate[
                                                      existingProjectRoleUpdateIndex
                                                    ].rolesToRemove!.push(
                                                      sameProductRole.role.id
                                                    );
                                                  } else {
                                                    projectRolesUpdate.push({
                                                      id: currentProject.project
                                                        .id,
                                                      rolesToRemove: [
                                                        sameProductRole.role.id,
                                                      ],
                                                      rolesToAdd: [],
                                                    });
                                                  }
                                                  accumulatedRoles[
                                                    sameProductRoleIdx
                                                  ] = currentRole;
                                                } else {
                                                  currentProject.availableProjectRoles?.push(
                                                    {
                                                      id: currentRole.role.id,
                                                      name: currentRole.role
                                                        .name,
                                                      productId:
                                                        currentRole.role
                                                          .productId,
                                                      product: {
                                                        id: currentRole.role
                                                          .product?.id,
                                                        name: currentRole.role
                                                          .product
                                                          ?.name as ProductName,
                                                        displayName:
                                                          currentRole.role
                                                            .product
                                                            ?.displayName,
                                                      },
                                                    }
                                                  );
                                                  if (
                                                    existingProjectRoleUpdateIndex >=
                                                    0
                                                  ) {
                                                    projectRolesUpdate[
                                                      existingProjectRoleUpdateIndex
                                                    ].rolesToRemove!.push(
                                                      currentRole.role.id
                                                    );
                                                  } else {
                                                    projectRolesUpdate.push({
                                                      id: currentProject.project
                                                        .id,
                                                      rolesToRemove: [
                                                        currentRole.role.id,
                                                      ],
                                                      rolesToAdd: [],
                                                    });
                                                  }
                                                }

                                                return accumulatedRoles;
                                              } else {
                                                accumulatedRoles.push(
                                                  currentRole
                                                );
                                                return accumulatedRoles;
                                              }
                                            },
                                            [] as UserAccountProject['_user_account_project_roles']
                                          );

                                        currentProject._user_account_project_roles =
                                          sortBy(
                                            userAccountProjectRoles,
                                            'teams'
                                          );

                                        calculatedDetails.assignedProjects?.push(
                                          currentProject
                                        );
                                        calculatedDetails.assignedProjectIds.push(
                                          currentProject.project.id
                                        );

                                        // Add 1 row span per project
                                        calculatedDetails.orgRowSpan =
                                          calculatedDetails.orgRowSpan + 1;

                                        // Add row span for project products.
                                        calculatedDetails.orgRowSpan =
                                          calculatedDetails.orgRowSpan +
                                          Math.max(
                                            currentProject.project
                                              .project_product_settings.length -
                                              1,
                                            0
                                          );
                                      }
                                      return calculatedDetails;
                                    },
                                    {
                                      assignedProjects: [],
                                      orgRowSpan: 0,
                                      assignedProjectIds: [],
                                    } as OrgRowSpanCalculatorType
                                  );

                                const showAddProjectButton =
                                  !!orgDetails?.admin_?.organizationMetadata
                                    ?.canManageAccess;
                                const availableProjects =
                                  org.projects?.rows?.filter(
                                    (project) =>
                                      !assignedProjectIds.includes(project.id)
                                  ) || [];
                                const availableTeams = org.teams?.rows?.filter(
                                  (team) => !assignedTeamIds.includes(team.id!)
                                );

                                let _orgRowSpan = orgRowSpan;
                                if (
                                  (showAddProjectButton &&
                                    (availableProjects.length > 0 ||
                                      assignedProjects?.length === 0)) ||
                                  (!showAddProjectButton &&
                                    assignedProjects?.length === 0)
                                ) {
                                  // Add extra row only if we are showing add-project button OR no projects available message.
                                  _orgRowSpan = orgRowSpan + 1;
                                }
                                return {
                                  showAddProjectButton,
                                  name: org.name,
                                  id: org.id,
                                  parentAccount: org.parentAccount,
                                  accountRoles,
                                  assignedTeams:
                                    userAccountDetails.tenancy_users_by_pk?._user_teams.filter(
                                      (team) =>
                                        team.team.team_accounts[0].accountId ===
                                        org.id
                                    ),
                                  assignedProjects,
                                  orgRowSpan: _orgRowSpan,
                                  availableTeams,
                                  availableProjects,
                                  isPrimaryAccount:
                                    userPrimaryAccountId === org.id,
                                  roles: orgDetails?.admin_?.accountById?.roles,
                                };
                              }
                            );

                            const userDetails: UserDetailsType = {
                              firstName:
                                userAccountDetails.tenancy_users_by_pk
                                  .firstName,
                              lastName:
                                userAccountDetails.tenancy_users_by_pk.lastName,
                              id: userAccountDetails.tenancy_users_by_pk.id,
                              userName:
                                userAccountDetails.tenancy_users_by_pk.userName,
                              picture:
                                userAccountDetails.tenancy_users_by_pk.picture,
                              orgs: userOrgs,
                              canManageAccess:
                                !!orgDetails?.admin_?.organizationMetadata
                                  ?.canManageAccess,
                            };

                            const _userDetails =
                              userAccountDetails.tenancy_users_by_pk?._user_teams.reduce(
                                (acc, team) => {
                                  return processTeamInheritedDetails({
                                    teamDetails: team.team,
                                    selectedUserDetails: acc,
                                  });
                                },
                                userDetails as UserDetailsType
                              );

                            return {
                              userDetails: _userDetails,
                              userDetailUpdates: {
                                projectRolesUpdate,
                                hasPendingUpdates: false,
                                roleIdsToAdd: [],
                                roleIdsToRemove: [],
                                teamIdsToAdd: [],
                                teamIdsToRemove: [],
                                projectIdsToAdd: [],
                                projectIdsToRemove: [],
                              },
                            };
                          }

                          return null;
                        }
                      );
                    },
                    onDoneAssignContext({ ctx, data }) {
                      // Update context only if incoming data has differences.
                      if (!data) return;

                      if (!ctx.userDetails[data.userDetails.id]) {
                        ctx.userDetails[data.userDetails.id] = {
                          state: data.userDetails,
                          userDetailUpdates: data.userDetailUpdates,
                          initialState: data.userDetails,
                        };
                      } else if (
                        !isEqual(
                          ctx.userDetails[data.userDetails.id].state,
                          data.userDetails
                        )
                      ) {
                        ctx.userDetails[data.userDetails.id].state =
                          data.userDetails;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading users: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  entry: (ctx) => {
                    if (
                      ctx.userInfo.canManageAccess &&
                      hasPendingUpdates(
                        ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                      )
                    ) {
                      sendManagerEvent({
                        type: 'manager.autoSaveUserDetails',
                      });
                    } else {
                      sendManagerEvent({
                        type: 'manager.userDetails.setStateToIdle',
                      });
                    }
                  },
                },
                autoSaveUserDetails: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (
                        !ctx.selectedUser ||
                        !ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                          ?.projectRolesUpdate
                      )
                        return;

                      if (
                        (
                          ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                            .projectRolesUpdate || []
                        ).length
                      ) {
                        const { data, error } =
                          await urqlGql.setUserAccountProjectsRoles({
                            userId: ctx.selectedUser,
                            projectRolesUpdate:
                              ctx.userDetails[ctx.selectedUser!]
                                ?.userDetailUpdates.projectRolesUpdate || [],
                          });

                        if (haveErrors(data)) {
                          throw new Error(data.errors?.[0]?.message);
                        }

                        if (error) {
                          throw new Error(error.message);
                        }
                      }
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      sendUserMessage({
                        type: 'success',
                        text: 'User details updated successfully',
                        autoClose: 3000,
                      });
                      if (!ctx.selectedUser) return;
                      ctx.userDetails[
                        ctx.selectedUser
                      ]!.userDetailUpdates.projectRolesUpdate = [];
                      ctx.userDetails[
                        ctx.selectedUser
                      ]!.userDetailUpdates.hasPendingUpdates = false;
                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user details: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                addTeamToOrg: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      teamDetails:
                        | GetTeamProjectsDetailsQuery['tenancy_teams_by_pk']
                        | undefined;
                      orgId: string;
                      team: NonNullable<AccountTeams>[number];
                    } | null> => {
                      if (
                        event.type !== 'manager.userDetails.addTeam' ||
                        !event.team.id
                      )
                        return null;

                      const { data, error } =
                        await urqlGql.getTeamProjectsDetails({
                          teamId: event.team.id,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return {
                        teamDetails: data?.tenancy_teams_by_pk,
                        orgId: event.orgId,
                        team: event.team,
                      };
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      if (!data || !ctx.selectedUser) return;

                      const selectedUserDetails =
                        ctx.userDetails[ctx.selectedUser];
                      if (!selectedUserDetails || !data.teamDetails) return;
                      const orgToUpdate = selectedUserDetails.state?.orgs.find(
                        (org) => org.id === data.orgId
                      );

                      if (!orgToUpdate) return;

                      // Remove the team from available teams
                      orgToUpdate.availableTeams =
                        orgToUpdate.availableTeams?.filter(
                          (availableTeam) => availableTeam.id !== data.team.id
                        );

                      orgToUpdate.assignedTeams?.push({
                        team: data.teamDetails,
                      });

                      if (selectedUserDetails) {
                        processTeamInheritedDetails({
                          teamDetails: data.teamDetails,
                          selectedUserDetails: selectedUserDetails.state!,
                        });
                      }

                      if (!selectedUserDetails.userDetailUpdates.teamIdsToAdd) {
                        selectedUserDetails.userDetailUpdates.teamIdsToAdd = [];
                      }

                      if (
                        !(
                          selectedUserDetails.userDetailUpdates
                            .teamIdsToRemove || []
                        ).includes(data.teamDetails.id)
                      ) {
                        selectedUserDetails.userDetailUpdates.teamIdsToAdd.push(
                          data.teamDetails.id
                        );
                      } else {
                        selectedUserDetails.userDetailUpdates.teamIdsToRemove =
                          (
                            selectedUserDetails.userDetailUpdates
                              .teamIdsToRemove || []
                          ).filter((teamId) => teamId !== data.teamDetails?.id);
                      }

                      selectedUserDetails.userDetailUpdates.hasPendingUpdates =
                        hasPendingUpdates(
                          selectedUserDetails.userDetailUpdates
                        );
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Failed to add team to org, Please try again`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                saveUpdates: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (event.type !== 'manager.userDetails.saveUpdates')
                        return;
                      const { userId, orgId } = event;
                      const userDetails = ctx.userDetails[userId];
                      if (!userDetails) return;

                      try {
                        const {
                          data: userAccountUpdateDetails,
                          error: userAccountUpdateDetailsError,
                        } = await urqlGql.updateUserAccountRoles({
                          id: userId,
                          accountId: orgId!,
                          firstName: userDetails.state?.firstName || '',
                          lastName: userDetails.state?.lastName || '',
                          roleIdsToAdd:
                            userDetails.userDetailUpdates.roleIdsToAdd || [],
                          roleIdsToRemove:
                            userDetails.userDetailUpdates.roleIdsToRemove || [],
                          teamIdsToAdd:
                            userDetails.userDetailUpdates.teamIdsToAdd || [],
                          teamIdsToRemove:
                            userDetails.userDetailUpdates.teamIdsToRemove || [],
                          projectIdsToAdd:
                            userDetails.userDetailUpdates.projectIdsToAdd || [],
                          projectIdsToRemove:
                            userDetails.userDetailUpdates.projectIdsToRemove ||
                            [],
                        });

                        const {
                          data: projectRoleUpdateDetails,
                          error: projectRoleUpdateDetailsError,
                        } = await urqlGql.setUserAccountProjectsRoles({
                          userId,
                          projectRolesUpdate:
                            userDetails.userDetailUpdates.projectRolesUpdate ||
                            [],
                        });

                        if (
                          userAccountUpdateDetailsError ||
                          projectRoleUpdateDetailsError
                        ) {
                          throw new Error('Failed to update user details');
                        }
                      } catch {
                        throw new Error('Something went wrong');
                      }
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      sendUserMessage({
                        type: 'success',
                        text: 'User details updated successfully',
                        autoClose: 3000,
                      });
                      if (!ctx.selectedUser) return;
                      // Update initial state to save state as a starting point.
                      ctx.userDetails[ctx.selectedUser].initialState =
                        ctx.userDetails[ctx.selectedUser].state;
                      ctx.userDetails[ctx.selectedUser]!.userDetailUpdates =
                        userDetailUpdateInitialState;
                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user details: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
              },
            },
          },
        },
      },
    },
    global: {
      type: 'parallel',
      states: {
        // none of these should be nested --> might make sense to have a localization machine
        appletTemplates: {
          id: 'appletTemplates',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getAppletTemplate',
                src: async (
                  ctx,
                  event
                ): Promise<
                  NonNullable<GetAppletTemplatesQuery['admin_']>['applets']
                > => {
                  const { data, error } = await urqlGql.getAppletTemplates();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return data?.admin_?.applets;
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.appletTemplates = data || [];
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching applet-templates: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        languages: {
          id: 'languages',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getLanguages',
                src: async (
                  ctx,
                  event
                ): Promise<{
                  languages: ManagerContext['languages'];
                  localizationSettingsConfig: ManagerContext['localizationSettingsConfig'];
                }> => {
                  const { data, error } = await urqlGql.getLanguages();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const languages = data?.admin_?.locales
                    ? sort(data?.admin_?.locales).asc('name')
                    : [];

                  if (languages.length) {
                    languages.unshift({
                      name: 'Select a language',
                      id: '',
                      locale: '',
                      __typename: 'Locale',
                      languageCode: '',
                    });
                  }

                  return {
                    languages,
                    localizationSettingsConfig:
                      data?.admin_?.localizationSettingsConfig,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.languages = data.languages;
                  ctx.localizationSettingsConfig =
                    data.localizationSettingsConfig;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching languages: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        // should only be 24 time zones
        timezones: {
          id: 'timezones',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getTimeZones',
                src: async (
                  ctx,
                  event
                ): Promise<ManagerContext['timezones'] | []> => {
                  const { data, error } = await urqlGql.getTimeZones();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const timeZoneData = data?.admin_?.timeZones;
                  const timeZones = timeZoneData
                    ? sort(timeZoneData).asc('label')
                    : [];

                  if (timeZoneData?.length) {
                    timeZones.unshift({
                      label: 'Select a time zone',
                      identifier: '',
                      id: '',
                      __typename: 'TimeZone',
                    });
                  }

                  return transformTimezonesData<Timezone>(timeZones);
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.timezones = data;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching TimeZones: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },

        // products isn't affected by current org so this shouldn't live here
        products: {
          initial: 'idle',
          states: {
            idle: {
              // entry: () => {
              // console.log('ready to load product info!');
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  return context.userInfo?.status === UserStatus.Active;
                },
              },
            },
            loading: {
              invoke: createInvokablePromise({
                src: async (
                  ctx,
                  event
                ): Promise<{
                  products: ManagerContext['products'];
                  allProducts: ManagerContext['allProducts'];
                }> => {
                  const { data, error } = await urqlGql.getProducts();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return {
                    allProducts: data?.admin_?.allProducts,
                    products: data?.admin_?.allToolProducts,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  if (data !== undefined) {
                    ctx.products = data.products;
                    ctx.allProducts = data.allProducts;
                  }
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching user orgs: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            error: {},
            loaded: {
              // entry: (ctx) => {
              // console.log('loaded products!', ctx.products);
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  // return context.selectedOrgId !== context.
                  return false;
                },
              },
            },
          },
        },
      },
    },
    superAdmin: {
      initial: 'idle',
      states: {
        idle: {},
        dataBaseSnapshot: {
          id: 'dataBaseSnapshot',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                src: async (_ctx, event): Promise<void> => {
                  if (event.type !== 'manager.createDbSnapshot') return;

                  const { data, error } = await urqlGql.makeDbSnapshot();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }
                },
                onDoneTarget: 'loaded',
                onErrorTarget: 'error',
                onDoneActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, _event) {
                      sendUserMessage({
                        type: 'success',
                        text: `DB snapshot created successfully`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error making db snapshot: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
      },
    },
  },
});
