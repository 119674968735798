import {
  Modal,
  ModalProps,
  Stepper,
  StepperItem,
  StepperWC,
} from '@pypestream/design-system';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider } from 'react-hook-form';

import {
  CreateProjectSteps,
  ProductCardsModal,
  ProjectFormFields,
} from '../..';
import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../../xstate/app.xstate';
import { useProjectWizardForm } from './useProjectWizardForm';

export type HomepageModalPropsType = {
  modalRef: React.RefObject<ModalProps> | null;
};

export const {
  ProjectNameField,
  ProjectDescriptionField,
  ProjectIconField,
  TimeZoneSelect,
  ProjectEnvironmentField,
  ProjectToolField,
} = new ProjectFormFields();

export const CreateProjectFlow = ({ modalRef }: HomepageModalPropsType) => {
  const swiperRef = useRef<StepperWC>(null);

  const { products, selectedProjectId, selectedProject } =
    useManagerCtxSelector((ctx) => ({
      products: ctx.tools?.filter(({ productId }) => {
        return ctx.products?.find(({ id }) => id === productId);
      }),
      conditionalProducts: ctx.createProjectTools,
      selectedProjectId: ctx.selectedProjectModal,
      selectedProject: ctx.projects?.find(
        (p) => p?.projectId === ctx.selectedProjectModal
      ),
    }));

  const {
    resetFormState,
    createProject,
    projectInfoForm,
    projectEnvironmentsForm,
    projectToolingForm,
    projectLocalizationForm,
    handleNextStep,
    handlePrevStep,
  } = useProjectWizardForm(swiperRef);

  const {
    ProjectInfo,
    ProjectEnvironments,
    ProjectTooling,
    ProjectLocalization,
    ProjectCreated,
  } = CreateProjectSteps;

  const handleModalClose = () => {
    if (modalRef?.current) {
      modalRef.current.open = false;
      resetFormState();
    }
  };

  return (
    <>
      <ProductCardsModal
        project={selectedProject}
        open={!!selectedProjectId}
        onClose={() => {
          sendManagerEvent({
            type: 'manager.deselectProjectModal',
          });
        }}
      />
      {createPortal(
        <Modal
          test-id="create-project-modal"
          ref={modalRef}
          usePortal={false}
          size="large"
          onClose={handleModalClose}
        >
          <Stepper id="stepper" ref={swiperRef}>
            <StepperItem name="project-info">
              <FormProvider {...projectInfoForm}>
                <ProjectInfo nextStep={handleNextStep} />
              </FormProvider>
            </StepperItem>
            <StepperItem name="project-environments">
              <FormProvider {...projectEnvironmentsForm}>
                <ProjectEnvironments
                  nextStep={handleNextStep}
                  prevStep={handlePrevStep}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem name="project-tools">
              <FormProvider {...projectToolingForm}>
                <ProjectTooling
                  nextStep={handleNextStep}
                  prevStep={handlePrevStep}
                  products={products}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem name="project-localization">
              <FormProvider {...projectLocalizationForm}>
                <ProjectLocalization
                  createProject={createProject}
                  nextStep={handleNextStep}
                  prevStep={handlePrevStep}
                />
              </FormProvider>
            </StepperItem>
            <StepperItem name="project-created">
              <ProjectCreated closeModal={handleModalClose} />
            </StepperItem>
          </Stepper>
        </Modal>,
        document.body
      )}
    </>
  );
};
