import {
  BlockList,
  Grid,
  GridCell,
  Icon,
  PageSection,
  Spacer,
  Stack,
  TextCaption,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent, useTranslation } from '@pypestream/translations';
import { FC } from 'react';
import { useController } from 'react-hook-form';

import {
  ProjectDescriptionField,
  ProjectIconField,
  ProjectNameField,
  ProjectToolField,
  TimeZoneSelect,
} from '.';
import { ReturnFormType } from '../../../../components';
import { ManagerContext } from '../../../../xstate/manager.xstate';

interface ProjectSettingsGeneralTabProps {
  form: ReturnFormType;
  projectId?: string;
  products: NonNullable<ManagerContext['tools']>;
  accountId?: string;
  disabled?: boolean;
}

export const ProjectSettingsGeneralTab: FC<ProjectSettingsGeneralTabProps> = ({
  products,
  projectId,
  accountId,
  form,
  disabled,
}) => {
  const { register, getValues, control } = form;

  const { fieldState } = useController({
    name: 'productIds',
    control,
    rules: { validate: (value) => !!value?.length },
  });
  const [t] = useTranslation();

  return (
    <>
      <TextTitle test-id="project-details-title" size="small">
        <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.title">
          General
        </TranslationComponent>
      </TextTitle>
      <Spacer size="large" />
      <PageSection>
        <TextTitle test-id="project-details-user-details-title" size="xsmall">
          <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.details.detailsCaption">
            Details
          </TranslationComponent>
        </TextTitle>
        <Spacer size="large" />

        <BlockList gutter="large">
          <Grid alignItems="center">
            <GridCell xsmall="12" medium="3">
              <TextSubtitle
                test-id="project-details-name-label"
                size="small"
                variant="secondary"
              >
                <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.details.name">
                  Name*
                </TranslationComponent>
              </TextSubtitle>
            </GridCell>
            <GridCell xsmall="12" medium="9">
              <ProjectNameField
                autosave
                disabled={disabled}
                variant="outlined"
                placeholder={
                  t(
                    'manager/projects:projectDetails.generalTab.details.placeholders.addName',
                    { defaultValue: 'Add Name' }
                  ) || ''
                }
                _form={form}
              />
            </GridCell>
          </Grid>

          <Grid alignItems="center">
            <GridCell xsmall="12" medium="3">
              <TextSubtitle
                test-id="project-details-description-label"
                size="small"
                variant="secondary"
              >
                <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.details.description">
                  Description
                </TranslationComponent>
              </TextSubtitle>
            </GridCell>
            <GridCell xsmall="12" medium="9">
              <ProjectDescriptionField
                autosave
                disabled={disabled}
                variant="outlined"
                placeholder={
                  t(
                    'manager/projects:projectDetails.generalTab.details.placeholders.addDescription',
                    { defaultValue: 'Add description' }
                  ) || ''
                }
                _form={form}
                {...register('description')}
              />
            </GridCell>
          </Grid>

          <Grid alignItems="center">
            <GridCell xsmall="12" medium="3">
              <TextSubtitle
                test-id="project-details-icon-label"
                size="small"
                variant="secondary"
              >
                <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.details.projectIcon">
                  Project Icon
                </TranslationComponent>
              </TextSubtitle>
            </GridCell>
            <GridCell xsmall="12" medium="9">
              <ProjectIconField
                readOnly={disabled}
                uploadProps={{ accountId, entityId: projectId }}
                form={form}
              />
            </GridCell>
          </Grid>
        </BlockList>
      </PageSection>

      <PageSection>
        <TextTitle test-id="project-details-tools-title" size="xsmall">
          <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.tools.toolsCaption">
            Tools
          </TranslationComponent>
        </TextTitle>
        <TextSubtitle
          test-id="project-details-tools-subtitle"
          size="small"
          variant="tertiary"
        >
          <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.tools.configureToolsYouNeed">
            Configure the tools you need for your project
          </TranslationComponent>
        </TextSubtitle>
        <Spacer size="large" />
        <Stack justifyContent="center" gutter="large">
          {products.map(({ productId, logo, label, name }) => {
            return (
              <ProjectToolField
                test-id={`project-${label?.replace(' ', '-').toLowerCase()}-tool`}
                key={productId}
                productId={productId}
                logo={logo}
                label={label}
                productName={name}
                confirmRemove
                form={form}
                disabled={disabled}
              />
            );
          })}
        </Stack>
        {fieldState.invalid && (
          <>
            <Spacer size="xlarge" />
            <TextCaption variant="danger" textAlign="center">
              <Icon
                name="warning"
                size="xsmall"
                weight="regular"
                className="u-margin-right-xsmall"
              />
              <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.tools.selectOneOrMoreProducts">
                Please select one or more tools
              </TranslationComponent>
            </TextCaption>
          </>
        )}
      </PageSection>

      <PageSection>
        <TextTitle test-id="project-details-localization-title" size="xsmall">
          <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.languageAndTimezone.languageCaption">
            Localization
          </TranslationComponent>
        </TextTitle>
        <Spacer size="large" />

        <BlockList gutter="large">
          <Grid>
            <GridCell xsmall="12" medium="3">
              <TextSubtitle
                test-id="project-details-timezone-label"
                size="small"
                variant="secondary"
              >
                <TranslationComponent i18nKey="manager/projects:projectDetails.generalTab.languageAndTimezone.defaultTimezone">
                  Timezone
                </TranslationComponent>
              </TextSubtitle>
            </GridCell>
            <GridCell xsmall="12" medium="9">
              <TimeZoneSelect
                autosave
                disabled={disabled}
                _form={form}
                variant="outlined"
                placeholder={
                  t(
                    'manager/projects:projectDetails.generalTab.languageAndTimezone.placeholders.selectTimezone',
                    { defaultValue: 'Select timezone' }
                  ) || ''
                }
                defaultValue={getValues('timeZoneId')}
                helpText={
                  t(
                    'manager/projects:projectDetails.generalTab.languageAndTimezone.helpText.timezone',
                    { defaultValue: 'Applies only to Analytics' }
                  ) || ''
                }
                {...register('timeZoneId')}
              />
            </GridCell>
          </Grid>
        </BlockList>
      </PageSection>
    </>
  );
};
